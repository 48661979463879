/** @jsx jsx */

import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import characterSVG from "assets/illustrations/cruella.svg";
import nameSVG from "assets/illustrations/name.svg";
import nsfwSVG from "assets/illustrations/nsfw.svg";
import AnimalSVG from "assets/illustrations/pig.svg";
import rudeSVG from "assets/illustrations/rude.svg";
import titleSVG from "assets/illustrations/title.svg";
import MythologySVG from "assets/illustrations/unicorn.svg";
import BarChart from "charts/BarChart/BarChart";
import BodyText from "components/BodyText";
import GraphTitle from "components/GraphTitle";
import { colors, fonts, grid } from "config";
import { Fragment } from "react";

const SubTitle = styled.span`
    display: none;
    font-style: italic;
    font-size: 14px;
    color: ${fonts.colors.grey};
    
    ${grid.breakpoints.desktop} {
        display: block;
    }
`;

const NickNames = () => (
    <Fragment>
        <GraphTitle css={css`margin-top: 0;`}>
            How do we nickname our work enemies?
        </GraphTitle>
        <SubTitle>
            Hover over bars to see nickname examples
        </SubTitle>

        <BarChart title="Variation on their real name" data={[{ value: 31, color: colors.blue }]}
                  image={nameSVG} hover="Sam, Jane, Bill, Jen" hoverDown/>

        <BarChart title="NSFW!" data={[{ value: 17, color: colors.blue }]}
                  image={nsfwSVG} hover="Too rude to show here!"/>

        <BarChart title="Rude" data={[{ value: 17, color: colors.blue }]}
                  image={rudeSVG} hover="Useless, Paranoid Android, Slippery Slater, Smelly Face"/>

        <BarChart title="Character" data={[{ value: 8, color: colors.blue }]}
                  image={characterSVG} hover="Cruella, Darth Vader, Tweedledee, Barbie"/>

        <BarChart title="Title" data={[{ value: 7, color: colors.blue }]}
                  image={titleSVG} hover="The Little Princess, Mr. Chatty, Big Chef, Lordship"/>

        <BarChart title="Mythology" data={[{ value: 5, color: colors.blue }]}
                  image={MythologySVG} hover="Poisoned Dwarf, Witch, Dragon, The Troll"/>

        <BarChart title="Animal" data={[{ value: 5, color: colors.blue }]}
                  image={AnimalSVG} hover="Snake, The Rottweiler, Moose, The Magpie"/>

        <BodyText marginTop={50} css={css`margin-bottom: 0;`}>
            Men tend to use nicknames based on physical attributes. Women, though, are far more
            creative, using mythological creatures, animals, royal titles and even Disney villains to
            refer to their (mostly female) adversaries.
        </BodyText>
    </Fragment>
);

export default NickNames;
