/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { colors, fonts, grid } from "config";
import { axisBottom, axisRight } from "d3-axis";
import { forceCollide, forceManyBody, forceSimulation, forceX, forceY } from "d3-force";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import "d3-transition";
import { withOnScreen } from "hocs/onScreen";
import map from "lodash/map";
import random from "lodash/random";
import React, { PureComponent } from "react";
import data from "../data";

const marginY = 20;
const marginX = 10;
const padding = 3;
const tooltipWidth = 200;

const GraphWrapper = styled.div`
    position: relative;
    padding-top: 20px;
    
    svg {
      overflow: visible;
    }
    
    .axis path, .axis line {
        stroke: ${fonts.colors.grey};
    }
    
    .axis text {
        fill: ${fonts.colors.grey};
        font-size: 14px;
        font-family: ${fonts.main};
        
        ${grid.breakpoints.desktop} {
            transform: translateY(5px);
        }
    }
    
    .axis .heading {
        fill: ${fonts.colors.body};
        font-size: 16px;
    }
    
    .start text {
        text-anchor: start;
        
        ${grid.breakpoints.desktop} {
            transform: translate(-10px, 5px);
        }
    }
`;

const ToolTip = styled.div`
    position: absolute;
    opacity: 0;
    top: -1000px;
    left: -1000px;
    cursor: default;
    
    background: ${colors.white};
    width: ${tooltipWidth}px;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
    font-size: 14px;
`;

class Graph extends PureComponent {

    constructor () {
        super();

        this.svgRef = React.createRef();
        this.tooltipRef = React.createRef();

        this.created = false;
    }

    render () {
        const { width, height } = this.props;

        return (
            <GraphWrapper>
                <svg width={width} height={height} ref={this.svgRef}/>
                <ToolTip ref={this.tooltipRef}/>
            </GraphWrapper>
        );
    }

    componentDidUpdate ({ height: oldHeight }) {
        const { width, height, onScreen } = this.props;

        if (!this.created && width && height) {
            this.createGraph();
            this.created = true;
        } else if (height !== oldHeight) {
            this.recreateGraph();
        }

        if (this.created) {
            this.drawAxis();
        }

        if (this.created && onScreen) {
            this.drawGraph();
        }
    }

    createGraph () {
        this.svg = select(this.svgRef.current);
        this.tooltip = select(this.tooltipRef.current);
        this.axis = this.svg.append("g").attr("class", "axis");
    }

    recreateGraph () {
        this.svg = select(this.svgRef.current);
        this.svg.selectAll("*").remove();

        this.createGraph();
    }

    drawAxis () {
        const { selected } = this.props;

        this.svg = select(this.svgRef.current);

        this.extent = selected === "frequency"
            ? [0, 0.6]
            : [-0.15, 0.15];

        this.tickFormat = (data) => {
            let label = `${data * 100}%`;

            if (selected === "frequency") {
                if (!this.isMobile()) {
                    if (data === 0) {
                        label = `${label} of work enemies`;
                    } else if (data === 0.1) {
                        label = "";
                    }
                }
            }

            return label;
        };

        if (this.isMobile()) {
            this.drawMobileAxis();
        } else {
            this.drawDesktopAxis();
        }

        if (selected === "frequency") {
            this.axis.selectAll(".tick")
                .attr("class", (data) => {
                    if (data === 0) {
                        return "tick start";
                    }

                    return "tick";
                });
        }
    }

    drawMobileAxis () {
        const { selected, width, height } = this.props;

        this.x = scaleLinear()
            .domain([0, 2])
            .range([marginX, width - (marginX)]);

        this.y = scaleLinear()
            .domain(this.extent)
            .range([height - (marginY), marginY]);

        this.yAxis = axisRight(this.y).ticks(7)
            .tickFormat(this.tickFormat);

        this.axis.attr("transform", `translate(${width - 100},0)`)
            .call(this.yAxis);

        this.axis.selectAll(".heading").remove();
        this.axis.selectAll(".extra").remove();

        this.axis
            .call(g => g.append("text")
                .attr("x", -(width / 2))
                .attr("y", 0)
                .attr("fill", fonts.colors.body)
                .attr("font-size", "16px")
                .attr("text-anchor", "start")
                .attr("class", "heading left-heading")
                .text(selected === "frequency" ? "More disliked" : "More male")
            )
            .call(g => g.append("text")
                .attr("x", -(width / 2))
                .attr("y", height)
                .attr("fill", fonts.colors.body)
                .attr("font-size", "16px")
                .attr("text-anchor", "start")
                .attr("class", "heading right-heading")
                .text(selected === "frequency" ? "Less disliked" : "More female")
            )
            .call(g => g.append("text")
                .attr("x", 10)
                .attr("y", 45)
                .attr("fill", fonts.colors.body)
                .attr("text-anchor", "start")
                .attr("class", "extra")
                .text("of work")
            )
            .call(g => g.append("text")
                .attr("x", 10)
                .attr("y", 65)
                .attr("fill", fonts.colors.body)
                .attr("text-anchor", "start")
                .attr("class", "extra")
                .text("enemies")
            );
    }

    drawDesktopAxis () {
        const { selected, width, height } = this.props;

        this.x = scaleLinear()
            .domain(this.extent)
            .range([marginX, width - (marginX)]);

        this.y = scaleLinear()
            .domain([0, 2])
            .range([marginY, height - (marginY)]);

        this.xAxis = axisBottom(this.x).ticks(7)
            .tickFormat(this.tickFormat);

        this.axis.attr("transform", `translate(0,${height - marginY})`)
            .call(this.xAxis);

        this.axis.selectAll(".heading").remove();
        this.axis.selectAll(".extra").remove();

        this.axis
            .call(g => g.append("text")
                .attr("x", marginX)
                .attr("y", -20)
                .attr("fill", fonts.colors.body)
                .attr("font-size", "16px")
                .attr("text-anchor", "start")
                .attr("class", "heading left-heading")
                .text(selected === "frequency" ? "Less disliked" : "More female")
            )
            .call(g => g.append("text")
                .attr("x", width - (marginX))
                .attr("y", -20)
                .attr("fill", fonts.colors.body)
                .attr("font-size", "16px")
                .attr("text-anchor", "end")
                .attr("class", "heading right-heading")
                .text(selected === "frequency" ? "More disliked" : "More male")
            );
    }

    drawGraph () {
        const { selected, width, height } = this.props;

        if (this.isMobile()) {
            this.nodes = map(data, (node, index) => ({
                index,
                label: node.label,
                size: 5,
                y: this.y(node[selected] / 100),
                fy: this.y(node[selected] / 100),
            }));

            this.simulation = forceSimulation(this.nodes)
                .force("x", forceX(width / 2))
                .force("collide", forceCollide().radius((data) => data.size + padding))
                .force("manyBody", forceManyBody().strength(5))
                .stop();
        } else {
            this.nodes = map(data, (node, index) => ({
                index,
                label: node.label,
                size: 5,
                x: this.x(node[selected] / 100),
                fx: this.x(node[selected] / 100),
            }));

            this.simulation = forceSimulation(this.nodes)
                .force("y", forceY(height / 2))
                .force("collide", forceCollide().radius((data) => data.size + padding))
                .force("manyBody", forceManyBody().strength(5))
                .stop();
        }

        for (let i = 0; i < 150; ++i) this.simulation.tick();

        const self = this;

        this.svg.selectAll(".dot")
            .data(this.nodes)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("fill", colors.purple)
            .attr("r", ({ size }) => size)
            .attr("cx", ({ x }) => x + random(1000, -1000))
            .attr("cy", () => random(0, height))
            .attr("opacity", 0)
            .on("mouseover", function ({ label, x, y }) {
                const left = (x > width / 2)
                    ? (x - tooltipWidth) - 10
                    : x + 10;

                select(this).transition()
                    .style("opacity", 0.5);

                self.tooltip.html(label)
                    .style("left", `${left}px`)
                    .style("top", `${y - 18}px`);

                self.tooltip.transition()
                    .duration(200)
                    .style("opacity", 1)
                    .style("top", `${y - 28}px`);
            })
            .on("mouseout", function ({ y }) {

                select(this).transition()
                    .style("opacity", 1);

                self.tooltip.transition()
                    .duration(500)
                    .style("opacity", 0)
                    .style("top", `${y - 38}px`)
                    .on("end", () => {
                        self.tooltip
                            .style("left", null)
                            .style("top", null);
                    });

            });

        this.svg.selectAll(".dot")
            .data(this.nodes)
            .transition()
            .duration(1000)
            .attr("opacity", 1)
            .attr("cx", ({ x }) => x)
            .attr("cy", ({ y }) => y);
    }

    isMobile () {
        const { height } = this.props;

        return height > 300;
    }

}

export default withOnScreen(Graph, 0.3);
