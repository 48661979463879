import styled from "@emotion/styled";
import { grid } from "config";

const HeaderTitle = styled.h1`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 32px;
    margin: 50px 0 10px;
  
    ${grid.breakpoints.desktop} {
      font-size: 50px;
      letter-spacing: -1.05px;
      line-height: 53px;
      margin: 100px 0 40px;
    }
`;

export default HeaderTitle;
