import styled from "@emotion/styled";
import { grid } from "../config";

const ColumnRight = styled.div`
    padding: 0 ${grid.gutter}px;
        
    ${grid.breakpoints.desktop} {
        float: right;
        width: ${grid.right};
    }
`;

export default ColumnRight;
