/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Container from "components/Container";
import { colors, grid } from "config";

const Wrapper = styled.div`
  background-color: ${colors.sky};
  margin: 50px 0 0;
  padding: 50px 0;
    
    ${grid.breakpoints.desktop} {
      padding: 100px 0 50px;
    }
`;

const GoodCoContainer = ({ children }) => (
    <Wrapper>
        <Container>
            {children}
        </Container>
    </Wrapper>
);

export default GoodCoContainer;
