/** @jsx jsx */

import { jsx } from "@emotion/core";
import dateImage from "assets/illustrations/calendar.svg";
import genderImage from "assets/illustrations/gender.svg";
import cakeImage from "assets/illustrations/generation.svg";
import PercentageCircles from "charts/PercentageCircles/PercentageCircles";
import PercentageWithKey from "charts/PercentageWithKey/PercentageWithKey";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import Boxes from "components/Boxes/Boxes";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import ColumnThird from "components/ColumnThird";
import Container from "components/Container";
import Link from "components/Link";
import RoundHeader from "components/RoundHeader/RoundHeader";
import Row from "components/Row";
import GoodCo from "./GoodCo/GoodCo";
import WhereTheyWork from "./WhereTheyWork/WhereTheyWork";
import WhoAreThey from "./WhoAreThey/WhoAreThey";

const Round2 = () => (
    <div id="round-2">
        <Container>
            <RoundHeader round={2} label="Enemy territory" yourHealth={6} enemyHealth={6}
                         title="So who do we have a problem with?">
                Boys will be (annoyed by) boys. Girls will be (annoyed by) girls. Your work enemy is likely to be the
                same gender as you.
            </RoundHeader>

            <Appear>
                <Row>
                    <ColumnThird>
                        <PercentageWithKey value={71} image={cakeImage}>
                            Same age or older
                        </PercentageWithKey>
                    </ColumnThird>
                    <ColumnThird>
                        <PercentageWithKey value={65} image={genderImage}>
                            Same gender
                        </PercentageWithKey>
                    </ColumnThird>
                    <ColumnThird>
                        <PercentageWithKey value={68} image={dateImage}>
                            We interact daily
                        </PercentageWithKey>
                    </ColumnThird>
                </Row>
            </Appear>

            <Appear>
                <Row marginTop={50}>
                    <ColumnRight>
                        <BodyText>
                            Maybe familiarity breeds contempt. Or perhaps competitiveness and authority are the key
                            factors in what make us fall out with a colleague. Either way,
                            the <Link href="https://good.co/blog/work-enemies/" target="_blank">relationships we have
                            with our managers</Link> seem vulnerable to problems. We’re less likely to make enemies
                            with someone who’s younger than us or lower on the company ladder.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>

            <Appear>
                <Row>
                    <ColumnLeft>
                        <WhoAreThey/>
                    </ColumnLeft>

                    <ColumnRight>
                        <WhereTheyWork/>
                    </ColumnRight>
                </Row>
            </Appear>

            <Appear>
                <Row>
                    <ColumnLeft>
                        <PercentageCircles value={85}>
                            say the relationship started out well&hellip; then things went wrong
                        </PercentageCircles>
                    </ColumnLeft>

                    <ColumnRight>
                        <BodyText>
                            Interestingly, almost all rifts begin with a relationship that once worked. This suggests we
                            know that a successful collaboration is possible. But before we can fix it, we need to
                            figure out what went wrong.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>
        </Container>

        <GoodCo/>

        <Boxes/>
    </div>
);

export default Round2;
