/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Appear from "../Appear";
import times from "lodash/times";
import { rounds, grid } from "../../config";

const BoxWrapper = styled.div`
  overflow: hidden;
  margin: 50px 0;
  
  ${grid.breakpoints.desktop} {
      margin: 100px 0 0;
  }
`;

const Box = styled.span`
  display: block;
  background-color: ${rounds.boxes.color};
  height: 5px;
  width: 2px;
  margin: 3px auto 0;
  
  ${grid.breakpoints.desktop} {
      margin: 7px auto 0;
  }
`;

const Boxes = () => (
    <BoxWrapper>
        {times(rounds.boxes.amount).map((index) => (
            <Appear key={index}>
                <Box/>
            </Appear>
        ))}
    </BoxWrapper>
);

export default Boxes;
