/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { colors, grid } from "../../../config";

const Wrapper = styled.a`
    color: ${colors.white};
    text-decoration: none;
    display: block;
    margin-top: 50px;
    
    :hover h5 {
        opacity: 0.8;
    }
    
    ${grid.breakpoints.desktop} {
        min-height: 370px;
        margin: 0;
    }
`;

const Image = styled.img`
    width: 100%;
`;

const Title = styled.h5`
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.42px;
    line-height: 24px;
    text-decoration: underline;
    margin: 20px 0 0;
    opacity: 1;
    transition: opacity 200ms;
`;

const Description = styled.p`
    line-height: 23px;
    letter-spacing: -0.23px;
    font-size: 16px;
    text-decoration: none;
`;

const RelatedLink = ({ image, title, description, href }) => (
    <Wrapper target="_blank" href={href}>
        <Image src={image} alt={title}/>
        <Title>{title}</Title>
        <Description>{description}</Description>
    </Wrapper>
);

RelatedLink.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default RelatedLink;
