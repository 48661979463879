/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { grid, colors } from "config";
import { PureComponent } from "react";
import { Sticky } from "react-sticky";
import NavLink from "./NavLink/NavLink";

const NavWrapper = styled.div`
    width: 100%;
    background-color: #ffffff;
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
    position: relative;
    padding: 0;
    max-height: 100vh;
    min-height: 65px;
    
    ${grid.breakpoints.desktop} {
        padding: 20px 0;
    }
`;

const Burger = styled.button`
    background: none;
    outline: none;
    border: 0;
    margin: 10px;
    z-index: 5;
    position: absolute;
    right: 0;
    top: 0;
    
    div {
        width: 35px;
        height: 2px;
        background-color: ${colors.darkGrey};
        margin: 8px 0;
        
        transition: all 300ms;
    }
    
    .bar1 {
        transform: ${props => props.open ? "rotate(-45deg) translate(-6px, 6px)" : "none"};
    }
    
    .bar2 {
        transform: ${props => props.open ? "rotate(45deg) translate(-8px, -8px)" : "none"};
    }
    
    ${grid.breakpoints.desktop} {
        display: none;
    }
`;

const NavList = styled.ul`
    text-align: center;
    list-style-type: none;
    padding: 55px 0 0 0;
    margin: 0;
    height: ${props => props.open ? "100vh" : 0};
    opacity: ${props => props.open ? 1 : 0};
    transition: opacity 1s;
    z-index: 4;
    overflow: auto;
    background: #fff;
    position: absolute;
    width: 100%;
    
    ${grid.breakpoints.desktop} {
        padding: 0;
        height: auto;
        opacity: 1;
        width: 800px;
        margin: auto;
        overflow: visible;
        position: relative;
    }
`;

const Padding = styled.div`
    padding-top: 60px;
    
    ${grid.breakpoints.desktop} {
        padding-top: 90px;
    }
`;

class NavBar extends PureComponent {
    constructor () {
        super();

        this.state = {
            open: false
        };

        this.onClick = this.onClick.bind(this);
    }

    render () {
        const { open } = this.state;

        return (
            <Sticky disableCompensation>
                {({ style, isSticky }) =>
                    <div>
                        {isSticky && <Padding/>}
                        <NavWrapper style={style} isSticky={isSticky}>
                            <Burger onClick={this.onClick} open={open}>
                                <div className="bar1"/>
                                <div style={{ opacity: open ? 0 : 1 }}/>
                                <div className="bar2"/>
                            </Burger>

                            <NavList open={open}>
                                <NavLink round={1} label="Breakdown" onClick={this.onClick}/>
                                <NavLink round={2} label="Enemy territory" onClick={this.onClick}/>
                                <NavLink round={3} label="Bad moves" onClick={this.onClick}/>
                                <NavLink round={4} label="Hurt feelings" onClick={this.onClick}/>
                                <NavLink round={5} label="Evasive action" onClick={this.onClick}/>
                                <NavLink round={6} label="Puzzle solved" onClick={this.onClick} end={true}/>
                            </NavList>
                        </NavWrapper>
                    </div>
                }
            </Sticky>
        );
    }

    onClick () {
        this.setState({
            open: !this.state.open
        });
    }
}

export default NavBar;
