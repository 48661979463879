/** @jsx jsx */

import { jsx } from "@emotion/core";
import { Fade } from "react-reveal";
import { animation } from "config";

const Appear = ({ children }) => (
    <Fade up distance={animation.appear.distance} fraction={animation.appear.fraction}>
        {children}
    </Fade>
);

export default Appear;
