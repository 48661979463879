import styled from "@emotion/styled";
import { grid } from "config";

const GraphTitle = styled.h3`
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    letter-spacing: -0.42px;
    line-height: 27px;
    
    ${grid.breakpoints.desktop} {
        line-height: 38px;
    }
`;

export default GraphTitle;
