/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { charts, colors, grid } from "config";
import Chart from "./Chart/Chart";
import Key from "./Key/Key";

const Wrapper = styled.div`
  overflow: hidden;
  margin: 20px 0 50px;
`;

const KeyWrapper = styled.div`
  padding-bottom: 25px;
  border-bottom: 1px solid ${colors.grey};
  overflow: hidden;
  
  ${grid.breakpoints.desktop} {
      padding-bottom: 5px;
  }
`;

export default () => (
    <Wrapper>
        <KeyWrapper>
            <Key color={charts.space.next} label="Next to us" value={18}/>
            <Key color={charts.space.area} label="Same area" value={47}/>
            <Key color={charts.space.room} label="Different rooms" value={26}/>
            <Key color={charts.space.premises} label="Different Premises" value={9} block={true}/>
        </KeyWrapper>

        <Chart/>
    </Wrapper>
);
