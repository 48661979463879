import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { grid } from "config";

const Row = styled.div`
  clear: both;
  overflow: hidden;
  margin: ${props => props.marginTop ? props.marginTop : 0}px -${grid.gutter}px 0;
`;

Row.propTypes = {
    marginTop: PropTypes.number
};

export default Row;
