/** @jsx jsx */

import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import BarChart from "charts/BarChart/BarChart";
import OneInCircles from "charts/OneInCircles/OneInCircles";
import PercentageCircles from "charts/PercentageCircles/PercentageCircles";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import Boxes from "components/Boxes/Boxes";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import Container from "components/Container";
import GraphTitle from "components/GraphTitle";
import HighlightSection from "components/HighlightSection";
import RoundHeader from "components/RoundHeader/RoundHeader";
import Row from "components/Row";
import SideText from "components/SideText";
import SideTitle from "components/SideTitle";
import { colors } from "config";
import NickNames from "./NickNames/NickNames";

const IframeWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
`;

const Iframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const Round5 = () => (
    <div id="round-5">
        <Container>
            <RoundHeader round={5} label="Evasive action"
                         yourHealth={7} enemyHealth={4}
                         title="How do we handle a colleague crisis?">
                When faced with an ongoing toxic working relationship, most people do the same thing: start looking for
                a new job. It seems work enemies can create shifts in our entire careers. Even if they do stick it out,
                employees will do anything to avoid their enemy, from moving desks and pulling sickies to skipping
                social events and meetings.
            </RoundHeader>

            <Appear>
                <Row css={css`margin-bottom: 50px;`}>
                    <ColumnLeft>
                        <PercentageCircles value={69}>
                            of people take action when faced with a work enemy
                        </PercentageCircles>
                    </ColumnLeft>
                    <ColumnRight>
                        <GraphTitle>
                            What action do you take?
                        </GraphTitle>

                        <BarChart title="Start looking for a new job" data={[{ value: 70, color: colors.red }]}/>
                        <BarChart title="Avoid work social events" data={[{ value: 26, color: colors.red }]}/>
                        <BarChart title="Move desks" data={[{ value: 21, color: colors.red }]}/>
                        <BarChart title="Pull sickies" data={[{ value: 17, color: colors.red }]}/>
                        <BarChart title="Quit the job" data={[{ value: 16, color: colors.red }]}/>
                        <BarChart title="Change working hours" data={[{ value: 14, color: colors.red }]}/>
                        <BarChart title="Skip meetings" data={[{ value: 7, color: colors.red }]}/>
                        <BarChart title="Leave the team" data={[{ value: 6, color: colors.red }]}/>
                    </ColumnRight>
                </Row>
            </Appear>
        </Container>

        <HighlightSection>
            <Container>
                <Appear>
                    <Row>
                        <ColumnLeft>
                            <SideTitle inverse>
                                Keep your friends close&hellip;
                            </SideTitle>
                            <SideText inverse>
                                Get more experiences and tips as our panel discuss work enemies.
                            </SideText>
                        </ColumnLeft>
                        <ColumnRight>
                            <IframeWrapper>
                                <Iframe src="https://player.vimeo.com/video/314243420"
                                        title="That would drive me insane!"
                                        frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true"
                                        allowFullScreen/>
                            </IframeWrapper>
                        </ColumnRight>
                    </Row>
                </Appear>
            </Container>
        </HighlightSection>

        <Container>
            <Appear>
                <Row marginTop={100}>
                    <ColumnLeft>
                        <OneInCircles total={3} highlight={1}>
                            people give a nickname to the colleague they dislike
                        </OneInCircles>
                    </ColumnLeft>
                    <ColumnRight>
                        <BodyText>
                            Sometimes, we can become part of the problem instead of trying to rise above it or resolve
                            it. Whether it’s to discuss them discreetly or just to let off some steam, many UK workers
                            give their enemy a (usually unflattering) nickname.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>
            <Appear>
                <Row>
                    <ColumnRight>
                        <NickNames/>
                    </ColumnRight>
                </Row>
            </Appear>
        </Container>

        <Boxes/>
    </div>
);

export default Round5;
