import styled from "@emotion/styled";
import { grid } from "../config";

const ColumnThird = styled.div`
    padding: 0 ${grid.gutter}px;
    
    ${grid.breakpoints.desktop} {
        float: left;
        width: 33%;
    }
`;

export default ColumnThird;
