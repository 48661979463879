/** @jsx jsx */

import { jsx, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import Promise from "bluebird";
import { withOnScreen } from "hocs/onScreen";
import times from "lodash/times";
import PropTypes from "prop-types";
import { Component } from "react";
import { colors, fonts, grid, players } from "config";
import enemyImage from "assets/illustrations/enemy/one.svg";
import heroImage from "assets/illustrations/you.svg";

const bounceFrames = keyframes`
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-7px);
    }
`;

const PlayerContainer = styled.div`
    overflow: hidden;
    position: relative;
    width: 150px;
    float: ${props => props.direction};
    
    ${grid.breakpoints.desktop} {
      width: 315px;
    }
`;

const PlayerIcon = styled.span`
    display: block;
    height: 50px;
    background: url(${props => props.direction === "left" ? heroImage : enemyImage}) no-repeat center ${props => props.direction};
    
    ${grid.breakpoints.desktop} {
      width: 50px;
      float: ${props => props.direction};
    }
    
    &.animate {
        animation: ${bounceFrames} .3s linear both 3;
    }
`;

const PlayerName = styled.span`
    display: block;
    font-family: ${fonts.secondary};
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 5px;
    text-align: ${props => props.direction};
    padding: 0;
    
    ${grid.breakpoints.desktop} {
        top: 0;
        position: absolute;
        left: ${props => props.direction === "left" ? "60px" : "auto"};
        right:  ${props => props.direction === "right" ? "60px" : "auto"};
        margin-top: 0;
        padding: 0 ${props => props.direction === "left" ? "50px" : 0} 0 ${props => props.direction === "right" ? "50px" : 0};
    }
`;

const Health = styled.div`
    overflow: hidden;
    margin-top: 10px;
    
    ${grid.breakpoints.desktop} {
        width: 300px;
        position: absolute;
        bottom: 0;
        left: ${props => props.direction === "left" ? "60px" : "auto"};
        right:  ${props => props.direction === "right" ? "60px" : "auto"};
        margin-top: 0;
    }
`;

const HealthBox = styled.span`
    display: block;
    background-color: ${props => props.filled === false ? colors.grey : players[props.direction].color};
    transition: background-color 0.5s ease-in;
    width: 13px;
    height: 20px;
    margin: 0 1px;
    float: ${props => props.direction};
    
    ${grid.breakpoints.desktop} {
        width: 23px;
    }
`;


class Player extends Component {

    constructor () {
        super();

        this.state = {
            currentHealth: 0,
            bounce: false
        };

        this.onPlayerClick = this.onPlayerClick.bind(this);
    }

    render () {
        const { float } = this.props;
        const { currentHealth, bounce } = this.state;

        return (
            <PlayerContainer direction={float}>
                <PlayerIcon direction={float} className={bounce ? "animate" : ""} onClick={this.onPlayerClick}/>
                <PlayerName direction={float}>
                    {players[float].name}
                </PlayerName>

                <Health direction={float}>
                    {times(players.maxHealth).map((index) => (
                        <HealthBox direction={float} filled={index < currentHealth} key={index}/>
                    ))}
                </Health>
            </PlayerContainer>
        );
    }

    onPlayerClick () {
        const { bounce } = this.state;

        if(!bounce) {
            return;
        }

        this.setState({
            bounce: false
        });

        setTimeout(() => {
            this.setState({
                bounce: true
            });
        }, 100);
    }

    shouldComponentUpdate ({ onScreen }) {
        if (onScreen !== this.props.onScreen) {
            this.animate();
        }

        return true;
    }

    animate () {
        this.addHealth()
            .then(() => this.bounce());
    }

    addHealth () {
        const { currentHealth } = this.state;
        const { health } = this.props;

        if (currentHealth !== health) {
            this.setState({
                currentHealth: currentHealth + 1
            });

            return Promise.delay(200)
                .then(() => this.addHealth());
        }

        return Promise.resolve();
    }

    bounce () {
        this.setState({
            bounce: true
        });

        return Promise.resolve();
    }
}

Player.propTypes = {
    health: PropTypes.number.isRequired,
    float: PropTypes.string.isRequired,
    onScreen: PropTypes.bool
};

export default withOnScreen(Player);
