/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { sharing } from "config";
import FacebookSVG from "../../assets/Share/Facebook/default.svg";
import FacebookFocusSVG from "../../assets/Share/Facebook/focused.svg";
import FacebookHoverSVG from "../../assets/Share/Facebook/hover.svg";
import TwitterSVG from "../../assets/Share/Twitter/default.svg";
import TwitterFocusSVG from "../../assets/Share/Twitter/focused.svg";
import TwitterHoverSVG from "../../assets/Share/Twitter/hover.svg";
import IconLink from "./IconLink";


const SocialWrapper = styled.div`
    float: right;
`;

const Social = () => (
    <SocialWrapper>

        <IconLink href={`https://twitter.com/intent/tweet?text=${sharing.twitter}&url=${window.location.href}`}
                  default={TwitterSVG}
                  hover={TwitterHoverSVG} focused={TwitterFocusSVG}/>

        <IconLink href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} default={FacebookSVG}
                  hover={FacebookHoverSVG}
                  focused={FacebookFocusSVG}/>
    </SocialWrapper>
);

export default Social;
