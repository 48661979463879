/** @jsx jsx */

import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import nailsImage from "assets/illustrations/nail.svg";
import DislikedBehaviours from "charts/DislikedBehaviours/DislikedBehaviours";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import Boxes from "components/Boxes/Boxes";
import Carousel from "components/Carousel/Carousel";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import Container from "components/Container";
import HighlightSection from "components/HighlightSection";
import RoundHeader from "components/RoundHeader/RoundHeader";
import Row from "components/Row";
import SideText from "components/SideText";
import SideTitle from "components/SideTitle";
import { carousel } from "config";

const Image = styled.div`
    background: url(${nailsImage}) no-repeat top left;
    height: 60px;
`;

const Round2 = () => (
    <div id="round-3">
        <Container>
            <RoundHeader round={3} label="Bad moves" yourHealth={5} enemyHealth={8}
                         title="What can't we stand about our colleagues?">
                Why does that colleague grind our gears? More than 60% of UK workers gave the same two reasons. It seems
                our work enemies are most guilty of “bending the truth to make themselves look good” and “commenting on
                others’ work performance”.
            </RoundHeader>
        </Container>

        <Container css={css`overflow: visible`}>
            <Appear>
                <DislikedBehaviours/>
            </Appear>
        </Container>

        <Container>
            <Row marginTop={100}>
                <ColumnLeft>
                    <Image/>
                    <SideText>
                        297 people claimed their work enemy clips their nails at work. Ewww!
                    </SideText>
                </ColumnLeft>
                <ColumnRight>
                    <BodyText>
                        People also hate being interrupted, being spoken to passive-aggressively or being treated
                        dismissively. Respect – or rather, a lack of it – is clearly a big factor here.
                    </BodyText>

                    <BodyText>
                        Men and women are disliked in the workplace for slightly different bad behaviours. Men become
                        work enemies by being NSFW, such as making inappropriate jokes, mocking others and swearing.
                        Women create animosity by gossiping about others, ostracising colleagues and oversharing about
                        their personal life.
                    </BodyText>
                </ColumnRight>
            </Row>
        </Container>


        <HighlightSection>
            <Container>
                <Row>
                    <ColumnLeft>
                        <SideTitle inverse css={css`margin-top: 20px;`}>
                            Colleagues in crisis
                        </SideTitle>
                        <SideText inverse>
                            UK workers explain what led them to consider a colleague as an enemy
                        </SideText>
                    </ColumnLeft>

                    <ColumnRight>
                        <Carousel items={carousel}/>
                    </ColumnRight>
                </Row>
            </Container>
        </HighlightSection>

        <Boxes/>
    </div>
);

export default Round2;
