/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { colors, grid } from "config";

const Link = styled.span`
    display: inline-block;
    background: ${colors.grey};
    padding: 10px 10px 14px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.2px;
    width: 50%;
    text-align: center;
    
    float: ${props => props.direction === "left" ? "right" : "left"};
    border-right: ${props => props.direction === "right" ? "5px" : 0} solid #fff;
    border-left: ${props => props.direction === "left" ? "5px" : 0} solid #fff;
    
    ${grid.breakpoints.desktop} {
        width: auto;
        float: none;
        
        margin-right: ${props => props.direction === "right" ? "5px" : 0};
        margin-left: ${props => props.direction === "left" ? "5px" : 0};
    }
`;

const SelectedBar = styled.span`
    display: block;
    position: absolute;
    ${props => props.direction}: ${props => props.selected ? 0 : `-100%`};
    bottom: 0;
    background: ${colors.green};
    height: 4px;
    width: 100%;
    transition: all 200ms linear;
`;

export default ({ onClick, selected, value, direction }) => (
    <Link onClick={() => onClick(value)} direction={direction}>
        View by {value}

        <SelectedBar selected={selected === value} direction={direction}/>
    </Link>
);
