/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import SpaceInvaders from "charts/SpaceInvaders/SpaceInvaders";
import BodyText from "components/BodyText";
import GraphTitle from "components/GraphTitle";
import { grid } from "config";

const Wrapper = styled.div`
    margin: 50px 0 0 0;
    
    ${grid.breakpoints.desktop} {
        margin: 0;
    }
`;

export default () => (
    <Wrapper>
        <GraphTitle>Where do they work?</GraphTitle>

        <SpaceInvaders/>

        <BodyText>
            Either way, we probably have to deal with them every day. If you’re reading this at work, your work enemy
            is quite likely to be within sight right now.
        </BodyText>
    </Wrapper>
);
