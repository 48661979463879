/** @jsx jsx */

import { css, Global, jsx } from "@emotion/core";
import { Fragment } from "react";
import { StickyContainer } from "react-sticky";
import Round1 from "rounds/Round1/Round1";
import Round2 from "rounds/Round2/Round2";
import Round3 from "rounds/Round3/Round3";
import Round4 from "rounds/Round4/Round4";
import Round5 from "rounds/Round5/Round5";
import Round6 from "rounds/Round6/Round6";
import { fonts } from "config";
import Achievement from "./Achievement/Achievement";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import NavBar from "./NavBar/NavBar";
import Related from "./Related/Related";

const styles = css`
    html {
        -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
    }
    
    body {
        background: white;
        margin: 0;
        padding: 0;
        font-family: ${fonts.main};
        color: ${fonts.colors.body};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    body * {
        box-sizing: border-box;
    }
`;

export default () => (
    <Fragment>
        <Global styles={styles}/>
        <Header/>
        <StickyContainer>
            <div id="round-1">
                <NavBar/>
                <Round1/>
            </div>
            <Round2/>
            <Round3/>
            <Round4/>
            <Round5/>
            <Round6/>
        </StickyContainer>
        <Achievement/>
        <Related/>
        <Footer/>
    </Fragment>
);
