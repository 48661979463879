/** @jsx jsx */

import { jsx } from "@emotion/core";
import BarChart from "charts/BarChart/BarChart";
import Appear from "components/Appear";
import GraphTitle from "components/GraphTitle";
import { Fragment } from "react";
import { colors } from "config";

export default () => (
    <Fragment>
        <Appear>
            <GraphTitle>Who are they?</GraphTitle>
        </Appear>

        <Appear>
            <BarChart title="In our team" data={[{ value: 38, color: colors.lime }]}/>
            <BarChart title="Our manager" data={[{ value: 28, color: colors.lime }]}/>
            <BarChart title="At our level" data={[{ value: 27, color: colors.lime }]}/>
            <BarChart title="Senior to us" data={[{ value: 25, color: colors.lime }]}/>
            <BarChart title="On a different team" data={[{ value: 20, color: colors.lime }]}/>
            <BarChart title="Junior to us" data={[{ value: 7, color: colors.lime }]}/>
            <BarChart title="Report to us" data={[{ value: 6, color: colors.lime }]}/>
        </Appear>
    </Fragment>
);
