/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { PureComponent } from "react";
import UpSVG from "assets/illustrations/arrow-down-right.svg";
import DownSVG from "assets/illustrations/arrow-up-right.svg";


const Wrapper = styled.div`
    position: absolute;
    left: -250px;
    top: ${props => props.up ? "auto" : "-5px"};
    bottom: ${props => props.up ? "-5px" : "auto"};
    transition: opacity 600ms;
    width: 250px;
    
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 18px;
    text-align: right;
    padding-right: 10px;
`;

const Arrow = styled.div`
    height: 80px;
    background: url(${props => props.up ? UpSVG : DownSVG}) no-repeat right ${props => props.up ? "bottom" : "top"};
`;

const Text = styled.span`
    display: block;
    padding-right: 20px;
`;

class Hover extends PureComponent {

    constructor () {
        super();

        this.state = {
            visible: false
        };
    }

    render () {
        const { text, up } = this.props;
        const { visible } = this.state;

        return (
            <Wrapper up={up} style={{ opacity: visible ? 1 : 0 }}>
                {up && <Text>{text}</Text>}
                <Arrow up={up}/>
                {up === false && <Text>{text}</Text>}
            </Wrapper>
        );
    }

    componentDidMount () {
        setTimeout(() => {
            this.setState({
                visible: true
            });
        }, 1);
    }
}

export default Hover;
