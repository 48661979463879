/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Container from "components/Container";
import { Sticky } from "react-sticky";
import { colors } from "config";
import logoImage from "assets/logos/Totaljobs.svg";
import Social from "components/Social/Social";

const Wrapper = styled.div`
    padding: 15px 0;
    background: ${colors.white};
    z-index: 100;
    overflow: hidden;
`;

const Logo = styled.a`
    background: url(${logoImage}) no-repeat center center;
    width: 180px;
    height: 40px;
    float: left;
    background-size: 180px auto;
    outline: none;
`;

const TopBar = () => (
    <Sticky>
        {({ style }) =>
            <Wrapper style={style}>
                <Container>
                    <Logo href="https://www.totaljobs.com/insidejob/"/>

                    <Social/>
                </Container>
            </Wrapper>
        }
    </Sticky>
);

export default TopBar;
