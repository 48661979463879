import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { colors, fonts } from "config";

const SideTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 40px;
    color: ${props => props.inverse ? colors.white : fonts.colors.body};
    margin:  0;
`;

SideTitle.propTypes = {
    inverse: PropTypes.bool
};

export default SideTitle;
