/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { PureComponent } from "react";
import Promise from "bluebird";

const duration = 1000;

const BarWrapper = styled.div`
    width: 100%;
    background-color: ${props => props.color};
    height: 25px;
    position: relative;
    margin: 5px 0;
`;

const BarFill = styled.div`
    transition: width ${duration}ms;
    width: ${props => props.visible ? props.value : 0}%;
    height: 25px;
    background-color: ${props => props.color};
`;

const BarLabel = styled.span`
    position: absolute;
    bottom: 0;
    left: ${props => props.value}%;
    padding: 0 0 4px 5px;
    font-size: 14px;
    letter-spacing: -0.29px;
    line-height: 17px;
    transition: all ${duration}ms;
    opacity: ${props => props.visible ? 1 : 0};
`;

class Bar extends PureComponent {

    constructor () {
        super();

        this.state = {
            showLabel: false
        };

    }

    render () {
        const { value, color, visible, bgColor } = this.props;
        const { showLabel } = this.state;

        return (
            <BarWrapper color={bgColor}>
                <BarFill value={value} color={color} visible={visible}/>
                <BarLabel value={value} visible={showLabel}>
                    {value}%
                </BarLabel>
            </BarWrapper>
        );
    }

    componentDidUpdate ({ visible }, prevState, snapshot) {
        if (this.props.visible !== visible) {
            Promise.delay(duration)
                .then(() => {
                    this.setState({
                        showLabel: true
                    });
                });
        }
    }
}

export default Bar;
