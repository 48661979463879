/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import SideText from "components/SideText";
import PropTypes from "prop-types";
import { grid } from "config";
import Bar from "./Bar/Bar";

const Wrapper = styled.div`
    margin: 0 0 50px;
    
    ${grid.breakpoints.desktop} {
      margin: 0;
    }
`;

const Label = styled.span`
    font-size: 50px;
    font-weight: bold;
    position: relative;
    margin: 0;
    line-height: 66px;
    letter-spacing: -1.04px;
`;

const OneInCircles = ({ total, highlight, children }) => (
    <Wrapper>
        <Label>{`${highlight} in ${total}`}</Label>
        <Bar total={total} highlight={highlight}/>
        {children && <SideText>{children}</SideText>}
    </Wrapper>
);

OneInCircles.propTypes = {
    total: PropTypes.number.isRequired,
    highlight: PropTypes.number.isRequired,
    children: PropTypes.string
};

export default OneInCircles;
