/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import EnemyImage from "assets/illustrations/enemy/one.svg";
import HeroImage from "assets/illustrations/you.svg";
import Promise from "bluebird";
import { Component } from "react";
import { withResizeDetector } from "react-resize-detector";

const delay = 200;
const youWidth = 50;
const enemyWidth = 40;
const speed = 20;
let enemySpeed = speed;

const Wrapper = styled.div`
    height: 100px;
    position: relative;
`;

const You = styled.img`
    position: absolute;
    transition: all ${delay}ms linear;
    width: ${youWidth}px;
    transform: scaleX(${props => props.reverse ? -1 : 1});
`;

const Enemy = styled.img`
    position: absolute;
    width: ${enemyWidth}px;
    transform: scaleX(${props => props.reverse ? -1 : 1});
`;


class Characters extends Component {

    constructor () {
        super();

        this.state = {
            you: {
                x: 0,
                y: -6,
                reverse: false,
            },
            enemy: {
                x: 100,
                y: 20,
                reverse: false,
            }
        };
    }

    render () {
        const { you, enemy } = this.state;

        return (
            <Wrapper>
                <You src={HeroImage} alt="You" style={{ bottom: you.y, left: you.x }} reverse={you.reverse}/>
                <Enemy src={EnemyImage} alt="You" style={{ bottom: enemy.y, left: enemy.x }} reverse={enemy.reverse}/>
            </Wrapper>
        );
    }

    componentDidMount () {
        this.animate();
    }

    animate () {
        this.animateYou();
        this.animateEnemy();

        return Promise.delay(delay).then(() => this.animate());
    }

    animateYou () {
        const { width } = this.props;
        const { you } = this.state;

        let { reverse } = you;

        const y = you.y === -6 ? -10 : -6;
        const newX = reverse ? -speed : speed;
        let x = you.x + newX;

        if (x > width - youWidth) {
            reverse = !reverse;
            x = width - youWidth;
        } else if (x < 0) {
            reverse = !reverse;
            x = 0;
        }

        this.setState({
            you: {
                x,
                y,
                reverse
            }
        });
    }

    animateEnemy () {
        const { width } = this.props;
        const { enemy, you } = this.state;

        // if the characters are going the same way keep them playing cat and mouse
        if (enemy.reverse === you.reverse) {
            if (Math.abs(you.x - enemy.x) < 10) {
                enemySpeed = speed + 2;
            } else if (Math.abs(you.x - enemy.x) > 100) {
                enemySpeed = speed - 2;
            }
        }

        let { reverse } = enemy;

        const y = enemy.y === 20 ? 25 : 20;
        const newX = reverse ? -enemySpeed : enemySpeed;
        let x = enemy.x + newX;

        if (x > width - enemyWidth) {
            reverse = !reverse;
            x = width - enemyWidth;
        } else if (x < 0) {
            reverse = !reverse;
            x = 0;
        }

        this.setState({
            enemy: {
                x,
                y,
                reverse
            }
        });
    }
}

export default withResizeDetector(Characters);
