/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import SideText from "components/SideText";
import { grid } from "config";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import Bar from "./Bar/Bar";

const Wrapper = styled.div`
    margin: 0 0 50px;
    
    ${grid.breakpoints.desktop} {
      margin: 0;
    }
`;

const Percentage = styled.span`
    font-size: 50px;
    font-weight: bold;
    position: relative;
    margin: 0;
    line-height: 66px;
    letter-spacing: -1.04px;
    opacity: ${props => props.visible ? 1 : 0};
    transition: opacity 1s ease;
`;

class PercentageCircles extends PureComponent {
    constructor () {
        super();

        this.state = {
            showLabel: false
        };

        this.animationFinished = this.animationFinished.bind(this);
    }

    render () {
        const { value, children } = this.props;
        const { showLabel } = this.state;

        return (
            <Wrapper>
                <Percentage visible={showLabel}>{value}%</Percentage>
                <Bar percentage={value} animationFinished={this.animationFinished}/>
                {children && <SideText>{children}</SideText>}
            </Wrapper>
        );
    }

    animationFinished () {
        this.setState({
            showLabel: true
        });
    }
}

PercentageCircles.propTypes = {
    value: PropTypes.number.isRequired,
    children: PropTypes.string
};

export default PercentageCircles;
