/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PercentageWithKey from "charts/PercentageWithKey/PercentageWithKey";
import ColumnThird from "components/ColumnThird";
import { withOnScreen } from "hocs/onScreen";
import PropTypes from "prop-types";
import { Fragment, Component } from "react";
import Bars from "./Bars";
import Promise from "bluebird";

const animationTime = 1000;

const KeyWrapper = styled.div`
    margin-top: 50px;
    overflow: hidden;
`;

const Fader = styled.div`
    transition: opacity ${animationTime}ms ease;
    opacity: ${props => props.visible ? 1 : 0};
`;

class BarChart extends Component {

    constructor () {
        super();

        this.state = {
            visible: 0
        };
    }

    render () {
        const { data, onScreen } = this.props;
        const { visible } = this.state;

        return (
            <Fragment>
                <Bars data={data} animationTime={animationTime} onScreen={onScreen}/>

                <KeyWrapper>
                    {data.map(({ percentage, color, image, label }, index) => (
                        <ColumnThird key={index}>
                            <Fader visible={visible > index}>
                                <PercentageWithKey value={percentage} color={color} image={image}>
                                    {label}
                                </PercentageWithKey>
                            </Fader>
                        </ColumnThird>
                    ))}
                </KeyWrapper>
            </Fragment>
        );
    }

    shouldComponentUpdate ({ onScreen }, nextState, nextContext) {
        if (onScreen !== this.props.onScreen) {
            this.animate();
        }

        return true;
    }

    animate () {
        const { data } = this.props;
        const { visible } = this.state;
        const length = data.length;

        if (visible === length) {
            return Promise.resolve();
        }

        return Promise.delay(animationTime)
            .then(() => {
                this.setState({
                    visible: visible + 1
                });
            })
            .then(() => this.animate());
    }
}

BarChart.propTypes = {
    data: PropTypes.array.isRequired,
    onScreen: PropTypes.bool
};

export default withOnScreen(BarChart);
