export const colors = {
    white: "#ffffff",
    black: "#000000",
    blue: "#29B8DC",
    grey: "#E9E9EA",
    red: "#F33D55",
    sky: "#BEECF5",
    purple: "#46003A",
    green: "#06BD30",
    yellow: "#F0BE00",
    pink: "#9531AF",
    lime: "#35D99A",
    darkGrey: "#97979D;"
};

export const grid = {
    left: "33.333333%",
    right: "66.666666%",
    gutter: 15,
    maxWidth: 792,
    minWidth: 320,
    mobile: 580,
    breakpoints: {
        desktop: "@media (min-width: 800px)"
    }
};

export const fonts = {
    main: "Verdana, Geneva, sans-serif",
    secondary: "'VT323', monospace",
    colors: {
        body: "#292A36",
        grey: "#757575;",
        link: colors.green
    }
};

export const players = {
    maxHealth: 10,
    left: {
        name: "Player 1: You",
        color: colors.green
    },
    right: {
        name: "Player 2: Them",
        color: colors.red
    }
};

export const rounds = {
    boxes: {
        amount: 11,
        color: colors.yellow
    }
};

export const charts = {
    space: {
        premises: "#35D99A",
        area: colors.blue,
        next: colors.purple,
        room: colors.red
    },
    circleBar: {
        duration: 200
    }
};

export const animation = {
    appear: {
        distance: "50px",
        fraction: 0.3
    }
};

export const related = [
    {
        image: require("./assets/related/SubjectToChange.png"),
        href: "https://www.totaljobs.com/insidejob/covering-at-work",
        title: "Subject to Change",
        description: "Do you hide your true self at work to win praise, promotion or even just acceptance? It’s called ‘covering’."
    },
    {
        image: require("./assets/related/WorkSpouses.png"),
        href: "https://www.totaljobs.com/insidejob/work-spouse",
        title: "The World of Work Spouses",
        description: "We all have that one colleague who makes work more bearable."
    },
    {
        image: require("./assets/related/HowLoveWorks.png"),
        href: "https://www.totaljobs.com/insidejob/how-love-works/",
        title: "How Love Works",
        description: "Revealed: the secrets of the UK's workplace romances."
    },
    {
        image: require("./assets/related/Loneliness.png"),
        href: "https://www.totaljobs.com/insidejob/how-to-combat-the-rise-of-workplace-loneliness/",
        title: "Loneliness in the Workplace",
        description: "How you can support a colleague who’s feeling lonely."
    }
];

export const carousel = [
    "She managed to make me invisible in the company. During work meetings, when it would be my turn to speak, I would be completely ignored, not even looked at in the face.",
    "This employee called me names in front of customers on busy shifts as well as berating my intelligence whilst making me feel incompetent at my job.",
    "This person would undermine my personality and decisions to colleagues but not to me. She would give an image of niceness. It was hard for other people to see what she was doing to me.",
    "My manager sees me stressed and worn out, but STILL pushes my buttons to get more revenue in, and takes credit for my work regularly. It’s relentless and it’s wearing me down.",
    "Their behaviour doesn't bother me. I just avoid them and automatically feel a drive to perform better than them to prove I’m far superior and my work should speak for itself."
];

export const sharing = {
    "twitter": "We discovered that 6 in 10 UK workers feel they have a ‘work enemy’. Why do our working relationships turn toxic? And what can we do to fix them? Find out with @totaljobsuk"
};
