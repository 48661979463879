/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { grid } from "config";
import GoodCoLogo from "./Logo/Logo";

const Wrapper = styled.p`
    margin: 0 0 50px;
    font-size: 16px;
    line-height: 26px;
    
    ${grid.breakpoints.desktop} {
        margin: 0 0 100px;
    }
`;

const GoodCoSideIntro = () => (
    <Wrapper>
        Workplace culture experts <GoodCoLogo/> explain...
    </Wrapper>
);

export default GoodCoSideIntro;
