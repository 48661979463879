import styled from "@emotion/styled";
import {grid} from "config";

const HeaderIntro = styled.p`
    font-size: 16px;
    letter-spacing: -0.33px;
    line-height: 27px;
    margin: 0 0 70px;
  
    ${grid.breakpoints.desktop} {
        width: 384px;
    }
 
`;

export default HeaderIntro;
