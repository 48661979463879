/** @jsx jsx */

import { css, jsx } from "@emotion/core";
import CrySVG from "assets/illustrations/cry.svg";
import PencilDecline from "assets/illustrations/down.svg";
import NightmareSVG from "assets/illustrations/nightmare.svg";
import PencilSame from "assets/illustrations/same.svg";
import TiredSVG from "assets/illustrations/tired.svg";
import PencilUp from "assets/illustrations/up.svg";
import PercentageCircles from "charts/PercentageCircles/PercentageCircles";
import PercentageWithKey from "charts/PercentageWithKey/PercentageWithKey";
import StackedBar from "charts/StackedBar/StackedBar";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import Boxes from "components/Boxes/Boxes";
import ColumnHalf from "components/ColumnHalf";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import Container from "components/Container";
import GoodCoContainer from "components/GoodCo/GoodCoContainer";
import GoodCoSideIntro from "components/GoodCo/GoodCoSideIntro";
import GraphTitle from "components/GraphTitle";
import RoundHeader from "components/RoundHeader/RoundHeader";
import Row from "components/Row";
import SideText from "components/SideText";
import SideTitle from "components/SideTitle";
import { colors } from "config";
import BarCharts from "./BarCharts/BarCharts";

const Round2 = () => (
    <div id="round-4">
        <Container>
            <RoundHeader round={4} label="Hurt feelings" yourHealth={1} enemyHealth={9}
                         title="How does having a toxic working relationship affect us?">
                Working relationships are personal. So when we feel antagonised by a colleague’s behaviour, it hurts.
                Not just our feelings but our careers, too. The feeling of being mistreated by a colleague triggers very
                different emotional responses in men and women.
            </RoundHeader>

            <Appear>
                <Row>
                    <ColumnLeft css={css`padding-bottom: 50px`}>
                        <img src={CrySVG} alt="Crying or getting emotional" css={css`margin-bottom: 20px;`}/>
                        <SideTitle>Crying or getting emotional</SideTitle>
                        <SideText>is the most common response to having a work enemy</SideText>
                    </ColumnLeft>

                    <ColumnRight>
                        <BodyText>
                            Women are most likely to get tearful, with 6 in 10 saying they cry or get emotional because
                            of their work enemy. Men, on the other hand, are most likely to respond by isolating
                            themselves. Women seem to let their emotions surface, while men hide them.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>
            <Row>
                <ColumnRight>
                    <BarCharts/>
                </ColumnRight>
            </Row>
            <Appear>
                <Row>
                    <ColumnRight>
                        <BodyText>
                            There’s also a contrast when it comes to more directly healthy and unhealthy responses to
                            having a work enemy. To take their minds off the problem, men are more likely than women to
                            pick up a sport or hobby. Women, however, say they’re more likely to pick up a drink or a
                            cigarette.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>
        </Container>

        <GoodCoContainer>
            <Row>
                <ColumnLeft>
                    <Appear>
                        <GoodCoSideIntro/>
                    </Appear>
                </ColumnLeft>
                <ColumnRight>
                    <Appear>
                        <GraphTitle>
                            Why difficult workplace relationships affect women more negatively than men
                        </GraphTitle>
                    </Appear>
                    <Appear>
                        <BodyText marginTop={50}>
                            When workplace relationships are difficult, women are more likely to be negatively affected
                            than men. Women tend to be more emotionally invested in relationships whereas men view
                            workplace friendships in more functional terms – for example, how a friendship can further
                            one’s career. So it’s not surprising that, compared to men, women reported that having an
                            enemy at work was a cause for considerable distress.
                        </BodyText>
                        <BodyText>
                            However, another explanation could be that men underplay the adverse effects of having a
                            work enemy. Men tend to under report and misidentify symptoms of emotional distress. While
                            women appear to suffer more from fraught workplace relationships, it may be that they’re
                            also more emotionally literate and able to describe how they feel.
                        </BodyText>
                    </Appear>
                </ColumnRight>
            </Row>
        </GoodCoContainer>

        <Container>
            <Appear>
                <Row marginTop={100}>
                    <ColumnLeft>
                        <PercentageCircles value={57}>
                            of people think about this colleague outside of work
                        </PercentageCircles>
                    </ColumnLeft>
                    <ColumnRight>
                        <BodyText>
                            If we find ourselves in a bad working relationship, we can’t just clock out of it when
                            working hours are over. Almost a third of us have lost sleep over a work enemy. And for
                            many, this person is literally a nightmare.
                        </BodyText>

                        <Row>
                            <ColumnHalf>
                                <PercentageWithKey value={30} image={TiredSVG}>
                                    have lost sleep over a work enemy
                                </PercentageWithKey>
                            </ColumnHalf>
                            <ColumnHalf>
                                <PercentageWithKey value={17} image={NightmareSVG}>
                                    have even had a nightmare about this colleague
                                </PercentageWithKey>
                            </ColumnHalf>
                        </Row>
                    </ColumnRight>
                </Row>
            </Appear>

            <Appear>
                <Row marginTop={50}>
                    <ColumnLeft>
                        <PercentageCircles value={77}>
                            of people with a colleague crisis are not happy at work
                        </PercentageCircles>
                    </ColumnLeft>
                    <ColumnRight>
                        <BodyText>
                            As well as the emotional cost, feeling antagonised by a colleague affects our job
                            performance, too. Unsurprisingly, the vast majority of people who feel they have a
                            problematic colleague are unhappy at work. Perhaps as a result, almost a third say it’s
                            making us worse at our jobs.
                        </BodyText>

                        <BodyText>
                            While a large percentage of people claim they refuse to let it affect their work
                            performance, this suggests it’s absorbing a significant amount of their energy that could be
                            better spent. Curiously, 1 in 10 say it’s actually improved their performance at work. More
                            on that later...
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>

            <Appear>
                <GraphTitle>
                    How having a work enemy affects performance at work…
                </GraphTitle>

                <StackedBar data={[{
                    percentage: 58,
                    color: colors.purple,
                    label: "No effect",
                    image: PencilSame
                }, {
                    percentage: 30,
                    color: colors.blue,
                    label: "Performance declines",
                    image: PencilDecline
                }, {
                    percentage: 11,
                    color: colors.red,
                    label: "Performance improves",
                    image: PencilUp
                }]}/>
            </Appear>
        </Container>

        <Boxes/>
    </div>
);

export default Round2;
