/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Promise from "bluebird";
import sumBy from "lodash/sumBy";
import PropTypes from "prop-types";
import { Component } from "react";

const BarContainer = styled.div`
  overflow: hidden;
  margin: 30px 0 0;
`;

const Bar = styled.span`
    display: block;
    width: ${props => props.visible ? props.width : 0}%;
    height: 60px;
    float: left;
    background-color: ${props => props.color};
    border: 1px solid #ffffff;
    transition: all ${props => props.animationTime}ms;
`;

class Bars extends Component {

    constructor () {
        super();

        this.state = {
            visible: 0
        };
    }

    render () {
        const { data, animationTime } = this.props,
            total = sumBy(data, "percentage");

        const { visible } = this.state;

        return (
            <BarContainer>
                {data.map(({ percentage, color }, index) => (
                    <Bar key={index}
                         width={(percentage / total) * 100}
                         visible={visible > index}
                         index={index}
                         color={color}
                         animationTime={animationTime}/>
                ))}
            </BarContainer>
        );
    }

    shouldComponentUpdate ({ onScreen }, nextState, nextContext) {
        if (onScreen !== this.props.onScreen) {
            this.animate(onScreen);
        }

        return true;
    }

    animate (onScreen) {
        const { data, animationTime } = this.props;
        let { visible } = this.state;

        const length = data.length;

        if (onScreen) {
            visible = visible + 1;
        } else {
            visible = visible - 1;
        }

        this.setState({
            visible
        });

        if (visible === 0 || visible === length) {
            return Promise.resolve();
        }


        return Promise.delay(animationTime)
            .then(() => this.animate(onScreen));
    }
}

Bars.propTypes = {
    data: PropTypes.array.isRequired,
    animationTime: PropTypes.number.isRequired,
    onScreen: PropTypes.bool
};

export default Bars;
