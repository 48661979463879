/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Appear from "components/Appear";
import { grid } from "config";
import times from "lodash/times";
import { fonts, rounds } from "../../config";
import BodyText from "../BodyText";
import Container from "../Container";
import Characters from "./Characters";

const numberOfBoxes = 7;

const Header = styled.div`
    font-family: ${fonts.secondary};
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.72px;
    line-height: 25px;
    font-size: 25px;
    margin: 100px 0 50px;
`;

const BoxWrapper = styled.div`
    margin: 10px;
    display: inline-block;
    vertical-align: bottom;
    
    ${grid.breakpoints.desktop} {
        margin: 0 10px;
    }
`;

const Box = styled.span`;
    display: inline-block;
    width: 5px;
    height: 2px;
    background-color: ${rounds.boxes.color};
    margin: 0 2px 6px;
`;

const Title = styled.span`
    width: 130px;
    display: inline-block;
    vertical-align: middle;
    
    ${grid.breakpoints.desktop} {
        width: auto;
    }
`;

const Achievement = () => (
    <Container>
        <Appear>
            <Header>
                <BoxWrapper>
                    {times(numberOfBoxes, (index) => <Box key={index}/>)}
                </BoxWrapper>
                <Title>
                    Achievement unlocked!
                </Title>
                <BoxWrapper>
                    {times(numberOfBoxes, (index) => <Box key={index}/>)}
                </BoxWrapper>
            </Header>
        </Appear>

        <Appear>
            <BodyText center>
                During our working life, we'll spend more time with our colleagues than with our own family. So it makes
                sense to try treating our colleagues as if they were family – we can’t choose them, but we can choose
                how we
                deal with them. Conflicts are part of every relationship we have. But it’s a relief to remember they’re
                often solvable, too.
            </BodyText>
        </Appear>
        <Characters/>
    </Container>
);

export default Achievement;
