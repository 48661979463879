/** @jsx jsx */

import { jsx } from "@emotion/core";
import BarChart from "charts/BarChart/BarChart";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import GoodCoContainer from "components/GoodCo/GoodCoContainer";
import GoodCoSideIntro from "components/GoodCo/GoodCoSideIntro";
import GraphTitle from "components/GraphTitle";
import Reference from "components/Reference";
import Row from "components/Row";
import { colors } from "config";

export default () => (
    <GoodCoContainer>
        <Appear>
            <Row>
                <ColumnLeft>
                    <GoodCoSideIntro/>

                    <BarChart title="Most ‘work enemies’ are the same gender" bgColor={colors.white}
                              data={[{ value: 65, color: colors.pink }]}/>
                    <BarChart title="Most ‘work spouses’ are the same gender*" bgColor={colors.white}
                              data={[{ value: 73, color: colors.pink }]}/>

                    <Reference>
                        *Data collected from a 2017 Totaljobs survey.
                    </Reference>

                </ColumnLeft>
                <ColumnRight>
                    <GraphTitle>
                        Why workplace friendships and fallouts are gender specific
                    </GraphTitle>

                    <BodyText marginTop={50}>
                        Same gender friendships at work make sense. We naturally develop bonds with colleagues who are
                        similar to us. But similar attributes can also be seen as a threat. We may easily identify with
                        co-workers who are similar to us, but we can also draw comparisons, which can manifest in
                        rivalry. A healthy rivalry can be good for work performance. Too much can lead to acrimony. So a
                        same-gender colleague can be a considerable source of support, motivation or frustration.
                    </BodyText>
                </ColumnRight>
            </Row>
        </Appear>
    </GoodCoContainer>
);
