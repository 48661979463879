import styled from "@emotion/styled";
import { grid } from "../config";

const ColumnHalf = styled.div`
    padding: 0 ${grid.gutter}px;
        
    ${grid.breakpoints.desktop} {
        float: left;
        width: 50%;
    }
`;

export default ColumnHalf;
