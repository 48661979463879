import styled from "@emotion/styled";

const Reference = styled.span`
    display: block;
    font-size: 14px;
    margin: 20px 0 50px;
    width: 190px;
    letter-spacing: -0.29px;
    line-height: 17px;
`;

export default Reference;
