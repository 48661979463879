/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { grid } from "config";
import Key from "./Key/Key";

const Wrapper = styled.div`
    display: block;
    overflow: hidden;
    margin: 0 auto 30px;
    width: ${grid.minWidth}px;
    position: relative;
    
    ${grid.breakpoints.desktop} {
        margin: 0;
        width: auto;
    }
`;

const Icon = styled.span`
    display: block;
    float: left;
    height: 50px;
    width: 50px;
    background: url(${props => props.image}) no-repeat center center;
    margin-right: 10px;
`;

const Percentage = styled.span`
    font-size: 30px;
    font-weight: bold;
    position: relative;
    margin: 13px 0 0;
    float: left;
    
    ${grid.breakpoints.desktop} {
        float: none;
        font-size: 34px;
        line-height:  48px;
        margin: 0;
    }
`;

const PercentageWithKey = ({ image, value, color, children }) => (
    <Wrapper>
        {image && <Icon image={image}/>}
        <Percentage>{value}%</Percentage>
        <Key color={color}>
            {children}
        </Key>
    </Wrapper>
);

PercentageWithKey.propTypes = {
    value: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    image: PropTypes.string
};

export default PercentageWithKey;
