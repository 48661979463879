/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { grid } from "config";

const Wrapper = styled.div`
  overflow: hidden;
  float: left;
  width: 50%;
  min-height: 60px;
  position: relative;
  
  ${grid.breakpoints.desktop} {
      float: ${props => props.block ? "none" : "left"};
      width: ${props => props.block ? "100%" : "33.33%"};
  }
`;

const ColorBlock = styled.span`
  width: 15px;
  height: 15px;
  display: block;
  background-color: ${props => props.color};
  margin-top: 4px;
  position: absolute;
  top: 0;
  left: 0;
`;

const TextBlock = styled.span`
  margin-left: 30px;
  font-size: 18px;
  letter-spacing: -0.37px;
  padding-right: 10px;
  display: block;
  
  ${grid.breakpoints.desktop} {
    padding: 0;
  }
;`;

const Percentage = styled.span`
  display: block;
  font-size: 14px;
  letter-spacing: -0.2px;
  padding-top: 5px;
`;

const Key = ({ color, label, value, block = false }) => (
    <Wrapper block={block}>
        <ColorBlock color={color}/>
        <TextBlock>
            {label}

            <Percentage>
                {value}%
            </Percentage>
        </TextBlock>
    </Wrapper>
);

Key.propTypes = {
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    block: PropTypes.bool
};

export default Key;
