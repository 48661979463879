/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import consoleCSV from "assets/illustrations/console.svg";
import Container from "components/Container";
import { StickyContainer } from "react-sticky";
import { colors, grid } from "config";
import HeaderIntro from "./HeaderIntro/HeaderIntro";
import HeaderTitle from "./HeaderTitle/HeaderTitle";
import TopBar from "./TopBar/TopBar";

const HeaderWrapper = styled.div`
    z-index: 2;
    background: ${colors.purple};
    padding: 0;
    color: ${colors.white};
`;

const HeaderBackground = styled.div`
    background-image: url(${consoleCSV});
    padding-bottom: 90px;
    background-size: 280px auto;
    background-position: bottom right;
    background-repeat: no-repeat;
    
    ${grid.breakpoints.desktop} {
        padding-bottom: 60px;
        background-size: auto auto;
    }
`;

const Header = () => (
    <StickyContainer>
        <HeaderWrapper>
            <TopBar/>

            <Container>
                <HeaderBackground>
                    <HeaderTitle>
                        The world of&hellip; work&nbsp;enemies
                    </HeaderTitle>

                    <HeaderIntro>
                        They pinch our desk snacks. Talk over us in meetings. Sometimes they even stand in the way of
                        our career progression. A new Totaljobs survey found that 6 in 10 UK workers feel they have a
                        ‘work enemy’. So why do our working relationships turn sour? How does this affect us? And what
                        can we do to put things right?
                    </HeaderIntro>
                </HeaderBackground>
            </Container>
        </HeaderWrapper>
    </StickyContainer>
);

export default Header;
