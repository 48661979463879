/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import heroImage from "assets/illustrations/you.svg";

const GraphItem = styled.span`
  position: absolute;
  display: block;
  height: 15px;
  width: 15px;
  background-color: ${props => props.destroyed ? "" : props.color};
  border: 1px ${props => props.destroyed ? "dashed" : "solid"} ${props => props.color};
  left: ${props => 5 + (props.column - 1) * props.width}px;
  top: ${props => 10 + (props.row - 1) * 25}px;
  transform: scale(${props => props.visible ? 1 : 0});
  transition: transform 1s ease;
`;

const GraphLabel = styled.span`
  position: absolute;
  top: -1px;
  left: 20px;
  font-size: 14px;
  display: block;
  width: 50px;
  user-select: none;
`;

const GraphIcon = styled.span`
  position: absolute;
  background: url(${heroImage}) no-repeat center center;
  height: 50px;
  width: 50px;
  top: -25px;
  left: -20px;
  transition: all 1.5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transform: rotate(${props => props.destroyed ? "395" : 0}deg);
`;

const DataItem = ({ visible, row, column, width, color, label, icon, destroyed }) => (
    <GraphItem visible={visible} row={row} column={column} width={width}
               color={color} destroyed={destroyed} draggable="false">
        {label && <GraphLabel>{label}</GraphLabel>}
        {icon && <GraphIcon destroyed={destroyed}/>}
    </GraphItem>
);

export default DataItem;
