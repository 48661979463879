import styled from "@emotion/styled";
import { fonts } from "../config";

const GraphSubTitle = styled.span`
    display: block;
    font-style: italic;
    font-size: 14px;
    color: ${fonts.colors.grey};
`;

export default GraphSubTitle;
