import styled from "@emotion/styled";
import { fonts, grid } from "config";

const Header = styled.h5`
    font-family: ${fonts.secondary};
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: normal;
    margin: 10px 0 0;
    
    span {
        display: none;
    }
    
    ${grid.breakpoints.desktop} {
        margin: 10px 0 50px 0;
    
        span {
            display: initial;
        }
    }
`;

export default Header;
