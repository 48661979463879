import styled from "@emotion/styled";
import { grid } from "../config";

const Container = styled.div`
    display: block;
    max-width: ${grid.maxWidth}px;
    min-width: ${grid.minWidth}px;
    margin: auto;
    position: relative;
    padding: 0 20px;
    overflow: hidden;
    
    ${grid.breakpoints.desktop} {
      padding: 0;
    }
`;

export default Container;
