/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Component } from "react";

const Link = styled.a`
    background: url(${props => props.default}) no-repeat center center;
    height: 30px;
    width: 30px;
    float: left;
    margin-left: 15px;
    
    &:hover {
        background: url(${props => props.hover}) no-repeat center center;
    }
    
    &:focus {
        background: url(${props => props.focused}) no-repeat center center;
        outline: none;
    }
`;

class IconLink extends Component {

    render () {
        const { href } = this.props;

        return (
            <Link href={href} target="_blank"
                  default={this.props.default} hover={this.props.hover} focused={this.props.focused}/>
        );
    }

    componentDidMount () {
        this.preload(this.props.hover);
        this.preload(this.props.focused);
    }

    preload (src) {
        let image = new Image();
        image.src = src;
    }

}

IconLink.propTypes = {
    default: PropTypes.string.isRequired,
    hover: PropTypes.string.isRequired,
    focused: PropTypes.string.isRequired,
};

export default IconLink;
