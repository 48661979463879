/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import ColumnHalf from "../ColumnHalf";
import Container from "../Container";
import Row from "../Row";
import SideTitle from "../SideTitle";
import { colors, related } from "../../config";
import RelatedLink from "./RelatedLink/RelatedLink";

const RelatedWrapper = styled.div`
    background: ${colors.purple};
    padding: 50px 0;
`;

const Related = () => (
    <RelatedWrapper>
        <Container>
            <SideTitle inverse>
                Keep exploring your work self&hellip;
            </SideTitle>

            <Row marginTop={30}>
                {related.map(({ title, description, image, href }, index) => (
                    <ColumnHalf key={index}>
                        <RelatedLink title={title} description={description} image={image} href={href}/>
                    </ColumnHalf>
                ))}
            </Row>
        </Container>
    </RelatedWrapper>
);

export default Related;
