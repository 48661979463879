/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { colors, grid } from "config";

const Wrapper = styled.div`
    color: ${colors.white};
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => props.visible ? 1 : 0};
    z-index: ${props => props.visible ? 1 : 0};
    transition: opacity linear 500ms;
    width: 100%;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.37px;
    line-height: 31px;
    
    ${grid.breakpoints.desktop} {
        line-height: 40px;
        font-size: 20px;
    }
`;

const Quote = styled.div`
    color: ${colors.white};
    display: block;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.56px;
    line-height: 33px;
`;

class CarouselItem extends Component {

    constructor () {
        super();

        this.textRef = React.createRef();

        this.state = {
            height: 0
        };
    }

    render () {
        const { active, children } = this.props;

        return (
            <Wrapper visible={active}>
                <Quote>&#x201C;</Quote>
                <div ref={this.textRef}>{children}</div>
                <Quote>&#x201C;</Quote>
            </Wrapper>
        );
    }
}

CarouselItem.propTypes = {
    children: PropTypes.node.isRequired,
    active: PropTypes.bool.isRequired
};

export default CarouselItem;
