/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Component } from "react";
import { colors, grid } from "config";
import ArrowLeft from "./carousel-arrow-left.svg";
import ArrowRight from "./carousel-arrow-right.svg";
import CarouselItem from "./CarouselItem/CarouselItem";

const CarouselWrapper = styled.div`
    position: relative;
    height: 250px;
    margin-top: 20px;
    
    ${grid.breakpoints.desktop} {
        margin: 0;
    }
`;

const Controls = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 5;
`;

const Control = styled.span`
    cursor: pointer;
    display: block;
    height: 14px;
    width: 14px;
    border-radius: 14px;
    background: ${colors.white};
    opacity: ${props => props.active ? 1 : 0.5};
    float: left;
    margin: 3px 5px;
    transition: opacity linear 200ms;
    
    &:hover {
      opacity: 0.8;
    }
`;

const LeftControl = styled.span`
    cursor: pointer;
    display: block;
    height: 20px;
    width: 14px;
    float: left;
    margin-right: 20px;
    background: url(${ArrowLeft}) no-repeat center center;
    opacity: 0.5;
    transition: opacity linear 200ms;
    
    &:hover {
      opacity: 0.8;
    }
`;

const RightControl = styled.span`
    cursor: pointer;
    display: block;
    height: 20px;
    width: 14px;
    float: left;
    margin-left: 20px;
    background: url(${ArrowRight}) no-repeat center center;
    opacity: 0.5;
    transition: opacity linear 200ms;
    
    &:hover {
      opacity: 0.8;
    }
`;

class Carousel extends Component {

    constructor () {
        super();

        this.state = {
            currentSlide: 0
        };
    }

    render () {
        const { currentSlide } = this.state;
        const { items } = this.props;

        return (
            <CarouselWrapper>
                {items.map((item, index) => (
                    <CarouselItem active={index === currentSlide} key={index}>
                        {item}
                    </CarouselItem>
                ))}

                <Controls>
                    <LeftControl
                        onClick={() => this.changeSlide(currentSlide - 1)}
                    />

                    {items.map((item, index) => (
                        <Control active={index === currentSlide} key={index}
                                 onClick={() => this.changeSlide(index)}
                        />
                    ))}

                    <RightControl
                        onClick={() => this.changeSlide(currentSlide + 1)}
                    />
                </Controls>
            </CarouselWrapper>
        );
    }

    changeSlide (index) {
        const { items } = this.props;

        // loop back round if we need to
        if (index < 0) {
            index = items.length - 1;
        } else if (index > items.length - 1) {
            index = 0;
        }

        this.setState({
            currentSlide: index
        });
    }
}

Carousel.propTypes = {
    items: PropTypes.array.isRequired
};

export default Carousel;
