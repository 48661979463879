import styled from "@emotion/styled";
import { grid } from "config";

const Header = styled.h2`
    text-align: center;
    margin: 50px 0;
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 33px;
    
    ${grid.breakpoints.desktop} {
        margin: 100px 0 50px;
        font-size: 45px;
        line-height: 78px;
        letter-spacing: -0.94px;
    }
`;

export default Header;
