/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { grid } from "config";

const KeyWrapper = styled.div`
    overflow: hidden;
    position: relative;
    float: right;
    width: 170px;
    padding-left: 10px;
    
    ${grid.breakpoints.desktop} {
        float: none;
        margin: 20px 0 0;
        width: auto;
        padding-left: 10px;
    }
`;

const KeyIcon = styled.span`
    background-color: ${props => props.color};
    display: block;
    width: 20px;
    height: 20px;
    margin: 5px 0;
    
    ${grid.breakpoints.desktop} {
        float: left;
        margin: 0 10px 0 0;
    }
`;

const KeyLabel = styled.span`
    display: block;
    line-height: 27px;
    font-size: 16px;
    padding-top: ${props => props.color || props.length > 25 ? 0 : "23px"};
    
    ${grid.breakpoints.desktop} {
        padding: 0;
    }
`;

const Key = ({ color, children }) => (
    <KeyWrapper>
        {color && <KeyIcon color={color}/>}
        <KeyLabel color={color} length={children.length}>{children}</KeyLabel>
    </KeyWrapper>
);

Key.propTypes = {
    children: PropTypes.string.isRequired,
    color: PropTypes.string,
};

export default Key;
