/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { colors } from "config";

const BulletContainer = styled.span`
    position: absolute;
    height: 5px;
    width: 2px;
    display: block;
    background: ${colors.black};
`;

const Bullet = ({ x, y }) => (
    <BulletContainer style={{ top: y, left: x }}/>
);

export default Bullet;
