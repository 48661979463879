/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Container from "components/Container";
import Link from "components/Link";
import { colors, grid } from "config";
import FooterNav from "./FooterNav/FooterNav";
import TotemSVG from "assets/logos/Totaljobs-totem.svg";
import Social from "components/Social/Social";

const FooterWrapper = styled.div`
    margin: 50px 0;
`;

const FooterText = styled.span`
    display: block;
    font-size: 14px;
    letter-spacing: -0.29px;
    line-height: 27px;
    
    a {
        
    }
`;

const SocialContainer = styled.div`
    overflow: hidden;
    padding: 20px 0;
    
    ${grid.breakpoints.desktop} {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
    }
`;

const TotemImage = styled.img`
    float: right;
    margin-right: 15px;
    
    ${grid.breakpoints.desktop} {
        margin: 0;
    }
`;

const SocialLinks = styled.div`
    margin: 35px 40px 0 0;
    float: left;
    
    ${grid.breakpoints.desktop} {
        float: right;
    }
`;

const Divider = styled.div`
    border-bottom: 2px solid ${colors.grey};
    margin: 30px 0;
`;

const Copyright = styled.span`
    font-size: 12px;
    letter-spacing: -0.25px;
    line-height: 15px;
`;

const Footer = () => (
    <FooterWrapper>
        <Container>
            <FooterText>
                Totaljobs research of 7,076 UK workers - November 2018.
            </FooterText>
            <FooterText>
                Numbers may not add up to 100% due to rounding.
            </FooterText>
            <FooterText>
                Psychometrics insights provided by Dr Roni Mermelshtine at <Link target="_blank"
                                                                                 href="https://good.co">Good&Co</Link>.
            </FooterText>
            <FooterText>
                Created in collaboration with <Link target="_blank" href="https://beyondwordsstudio.com/">Beyond Words
                Studio</Link>.
            </FooterText>

            <SocialContainer>
                <TotemImage src={TotemSVG} alt="Totaljobs"/>

                <SocialLinks>
                    <Social/>
                </SocialLinks>
            </SocialContainer>

            <Divider/>

            <FooterNav/>

            <Copyright>
                &copy; Copyright and database rights Totaljobs Group Ltd 2019
            </Copyright>
        </Container>
    </FooterWrapper>
);

export default Footer;
