/** @jsx jsx */

import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import GraphSubTitle from "components/GraphSubTitle";
import GraphTitle from "components/GraphTitle";
import { grid } from "config";
import { PureComponent } from "react";
import Button from "./Button/Button";
import Graph from "./Graph/Graph";
import ResizeDetector from "react-resize-detector";

const desktopHeight = 200;
const mobileHeight = 600;

const Wrapper = styled.div`
    position: relative;
`;

const Header = styled.div`
    overflow: hidden;
    padding-bottom: 10px;
    
    .desktop {
        display: none;
    }
    
    ${grid.breakpoints.desktop} {
        .mobile {
            display: none;
        }
        
        .desktop {
            display: initial;
        }
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 20px;
    overflow: hidden;
    
    ${grid.breakpoints.desktop} {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
    }
`;

const GraphWrapper = styled.div`
    height: ${mobileHeight}px;
    width: 100%;
    
    @media (min-width: ${grid.mobile}px) {
        height: ${desktopHeight}px;
    }
`;

const Views = {
    FREQUENCY: "frequency",
    GENDER: "gender"
};

class DislikedBehaviours extends PureComponent {
    constructor () {
        super();

        this.state = {
            selected: Views.FREQUENCY
        };

        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onButtonClick (value) {
        this.setState({
            selected: value
        });
    }

    render () {
        const { selected } = this.state;

        return (
            <Wrapper>
                <Header>
                    <GraphTitle css={css`margin-top: 0;`}>
                        Most frequent disliked behaviours:
                    </GraphTitle>
                    <GraphSubTitle>
                        <span className="desktop">Hover over</span><span className="mobile">Tap</span> dots to see
                        behaviour.
                    </GraphSubTitle>

                    <ButtonWrapper>
                        <Button value={Views.FREQUENCY} onClick={this.onButtonClick} selected={selected}
                                direction="right"/>
                        <Button value={Views.GENDER} onClick={this.onButtonClick} selected={selected} direction="left"/>
                    </ButtonWrapper>
                </Header>


                <GraphWrapper>
                    <ResizeDetector
                        handleHeight handleWidth
                    >
                        {(width, height) => (
                            <Graph selected={selected} width={width} height={height}/>
                        )}
                    </ResizeDetector>
                </GraphWrapper>
            </Wrapper>
        );
    }

}

export default DislikedBehaviours;
