/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Promise from "bluebird";
import { charts, colors, fonts } from "config";
import { withOnScreen } from "hocs/onScreen";
import findIndex from "lodash/findIndex";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import maxBy from "lodash/maxBy";
import React, { Component } from "react";
import { withResizeDetector } from "react-resize-detector";
import Bullet from "./Bullet/Bullet";
import cannonImage from "./cannon.png";
import dataJSON from "./data";
import DataItem from "./DataItem/DataItem";

const columns = maxBy(dataJSON, "column").column;
const startingData = map(dataJSON, (item) => ({
    ...item,
    destroyed: false
}));

const GraphWrapper = styled.div`
    padding: 5px;
    position: relative;
    overflow: hidden;
    height: 300px;
    border-bottom: 1px solid ${colors.grey};
    -webkit-tap-highlight-color: rgba(0,0,0,0);
`;

const Cannon = styled.span`
    position: absolute;
    background: url(${cannonImage}) no-repeat center center;
    width: 36px;
    height: 44px;
    bottom: 0;
`;

const FinishedOverlay = styled.div`
    position: absolute;
    top: ${props => props.visible ? 0 : -1000}px;
    left: ${props => props.visible ? 0 : -1000}px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: ${props => props.visible ? 0.8 : 0};
    font-family: ${fonts.secondary};
    transition: opacity 600ms;
    z-index: ${props => props.visible ? 10 : 0};
    
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
    }
`;

const center = {
    row: 5,
    column: 10
};

class Chart extends Component {

    constructor () {
        super();

        this.state = {
            step: 0,
            data: startingData,
            bullets: []
        };

        this.animating = false;

        this.wrapperRef = React.createRef();
        this.cannonRef = React.createRef();

        this.isVisible = this.isVisible.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.restart = this.restart.bind(this);
    }

    render () {
        const { width } = this.props;
        const { data, bullets } = this.state;
        const columnWidth = width / columns;

        const isFinished = findIndex(data, { destroyed: false }) === -1;

        return (
            <GraphWrapper ref={this.wrapperRef}>
                {width && data.map(({ row, column, type, label, icon, destroyed }, index) => (
                    <DataItem key={index} visible={this.isVisible(row, column)} row={row} column={column} label={label}
                              width={columnWidth} color={charts.space[type]} icon={icon} destroyed={destroyed}/>
                ))}

                <Cannon ref={this.cannonRef}/>

                {bullets.map(({ x, y }, index) => (
                    <Bullet x={x} y={y} key={index}/>
                ))}

                <FinishedOverlay onClick={this.restart} visible={isFinished}>
                    <span>Level Clear</span>
                </FinishedOverlay>

            </GraphWrapper>
        );
    }

    isVisible (row, column) {
        const { step } = this.state;

        return ((row >= center.row - step && row <= center.row + step)
            && (column >= center.column - step && column <= center.column + step));
    }

    animate () {
        const { step } = this.state;

        this.setState({
            step: step + 1
        });

        if (center.column - step > 0 || center.row - step > 0) {
            return Promise.delay(100).then(() => this.animate());
        }

        return Promise.resolve();
    }

    restart () {
        this.setState({
            data: startingData
        });
    }

    shouldComponentUpdate ({ onScreen, width }, nextState, nextContext) {
        if (this.props.onScreen !== onScreen) {
            this.animate();
        }

        return true;
    }

    getRelativePosition (pageX) {
        const { left } = this.wrapperRef.current.getBoundingClientRect();
        const { width } = this.props;

        return Math.max(-18, Math.min((pageX - left - 18), (width - 18)));
    }

    onMouseMove ({ pageX }) {
        const cannonPosition = this.getRelativePosition(pageX);
        const cannon = this.cannonRef.current;

        cannon.style.left = `${cannonPosition}px`;
    }

    onMouseDown (event) {
        const { pageX } = event;
        const xPosition = this.getRelativePosition(pageX);
        const { bullets } = this.state;
        const { height } = this.props;

        this.setState({
            bullets: [
                ...bullets,
                {
                    x: xPosition + 18,
                    y: height - 40
                }
            ]
        });

        if (!this.animating) {
            this.animateBullets();
        }
    }

    animateBullets () {
        let { bullets, data } = this.state;
        const { width } = this.props;

        const columnWidth = width / columns;

        this.animating = true;

        bullets = flatMap(bullets, ({ x, y }) => {
            const position = {
                x,
                y: y - 5
            };

            // remove the bullet if it is off the canvas
            if (position.y < 0) {
                return [];
            }

            const destroyedIndex = findIndex(data, ({ column, row, destroyed }) => {
                const dataX = 5 + (column - 1) * columnWidth;
                const dataY = 10 + (row - 1) * 25;

                return destroyed === false && (position.x > dataX && position.x < dataX + 15 && position.y > dataY && position.y < dataY + 15);
            });

            if (destroyedIndex !== -1) {
                data = map(data, (item, index) => {
                    if (index !== destroyedIndex) {
                        return item;
                    }

                    return {
                        ...item,
                        destroyed: true
                    };
                });

                return [];
            }

            return position;
        });

        this.setState({
            bullets,
            data
        });

        if (bullets.length > 0) {
            return Promise.delay(10).then(() => this.animateBullets());
        }

        this.animating = false;
    }

    componentDidMount () {
        this.wrapperRef.current.addEventListener("mousemove", this.onMouseMove);
        this.wrapperRef.current.addEventListener("mousedown", this.onMouseDown);
    }
}

export default withOnScreen(withResizeDetector(Chart, { handleWidth: true, refreshMode: "debounce" }));
