/** @jsx jsx */

import { jsx, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import leftSVG from "assets/illustrations/flag/left.svg";
import rightSVG from "assets/illustrations/flag/right.svg";
import { PureComponent } from "react";
import Promise from "bluebird";

const angle = 35;

const waveLeft = keyframes`
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(${angle}deg);
    }
`;
const waveRight = keyframes`
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-${angle}deg);
    }
`;

const Wrapper = styled.div`
    position: relative;
    height: 50px;
    width: 80px;
    margin: auto;
`;

const LeftFlag = styled.span`
    display: block;
    background: url(${leftSVG}) left center no-repeat;
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 3px;
    
    &.animate {
        animation: ${waveLeft} 300ms linear both 1;
    }
`;

const RightFlag = styled.span`
    display: block;
    background: url(${rightSVG}) right center no-repeat;
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 3px;
    
    &.animate {
        animation: ${waveRight} 300ms linear both 1;
    }
`;

class Flags extends PureComponent {
    constructor () {
        super();

        this.state = {
            animate: true
        };
    }

    render () {
        const { animate } = this.state;

        return (
            <Wrapper>
                <LeftFlag className={animate ? "animate" : ""}/>
                <RightFlag className={animate ? "animate" : ""}/>
            </Wrapper>
        );
    }

    animate () {
        this.setState({
            animate: false
        });

        Promise.delay(10)
            .then(() => {
                this.setState({
                    animate: true
                });
            })
            .delay(5000)
            .then(() => this.animate());
    }

    componentDidMount () {
        this.animate();
    }
}

export default Flags;
