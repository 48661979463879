/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { grid } from "config";
import PropTypes from "prop-types";
import Appear from "../Appear";
import Flags from "./Flags/Flags";
import Players from "./Players/Players";
import RoundDescription from "./RoundDescription/RoundDescription";
import RoundLabel from "./RoundLabel/RoundLabel";
import RoundTitle from "./RoundTitle/RoundTitle";

const Wrapper = styled.div`
  overflow: hidden;
  padding: 50px 0 0;
  
  ${grid.breakpoints.desktop} {
      padding: 100px 0 0;
  }
`;

const RoundHeader = ({ title, label, children, round, yourHealth, enemyHealth }) => (
    <Wrapper>
        <Appear>
            <Flags/>
        </Appear>

        <Appear>
            <RoundLabel>Round {round}<span>: {label}</span></RoundLabel>
            <Players yourHealth={yourHealth} enemyHealth={enemyHealth}/>
        </Appear>

        <Appear>
            <RoundTitle>{title}</RoundTitle>
        </Appear>

        <Appear>
            <RoundDescription>
                {children}
            </RoundDescription>
        </Appear>
    </Wrapper>
);

RoundHeader.propTypes = {
    round: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    yourHealth: PropTypes.number.isRequired,
    enemyHealth: PropTypes.number.isRequired,
    dots: PropTypes.bool
};

export default RoundHeader;
