import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { colors, fonts } from "config";

const SideText = styled.p`
    margin: 10px 0 10px;
    font-size: 16px;
    line-height: 26px;
    color: ${props => props.inverse ? colors.white : fonts.colors.body};
    font-weight: normal;
`;

SideText.propTypes = {
    inverse: PropTypes.bool
};

export default SideText;
