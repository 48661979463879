/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { grid } from "config";
import Player from "./Player/Player";

const PlayersContainer = styled.div`
  overflow: hidden;
  margin-top: -30px;
  
  ${grid.breakpoints.desktop} {
      margin-top: 0;
  }
`;

const Players = ({ yourHealth, enemyHealth }) => (
    <PlayersContainer>
        <Player health={yourHealth} float="left"/>
        <Player health={enemyHealth} float="right"/>
    </PlayersContainer>
);

Players.propTypes = {
    yourHealth: PropTypes.number.isRequired,
    enemyHealth: PropTypes.number.isRequired
};

export default Players;
