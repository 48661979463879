/** @jsx jsx */

import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { colors, grid } from "config";
import { withOnScreen } from "hocs/onScreen";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import Bar from "./Bar/Bar";
import Hover from "./Hover/Hover";

const BarChartContainer = styled.div`
    width: 100%;
    font-size: 18px;
    letter-spacing: -0.37px;
    line-height: 26px;
    margin: 20px 0 0;
    position: relative;
`;

const BarTitle = styled.span`
    display: block;
    margin: 0 0 10px;
    padding-left: ${props => props.image ? "60px" : 0};
`;

const BarImage = styled.img`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`;

const BarsWrapper = styled.div`
    padding-left: ${props => props.image ? "60px" : 0};
`;

const HoverWrapper = styled.div`
    position: absolute;
    display: none;
    left: 0;
    top: 50%;
    
    ${grid.breakpoints.desktop} {
        display: block;
    }
`;

class BarChart extends PureComponent {

    constructor () {
        super();

        this.state = {
            showHover: false
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    render () {
        const { title, image, onScreen, data = [], bgColor = colors.grey, hover, hoverDown = false, hoverUp = true, hoverAlwaysOn = false } = this.props;
        const { showHover } = this.state;

        return (
            <BarChartContainer onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                {title && <BarTitle image={image}>{title}</BarTitle>}

                {image && <BarImage src={image} css={css`margin-top: ${data.length > 1 ? 15 : 0}px;`}/>}

                <BarsWrapper image={image}>
                    {data.map(({ color, value }, index) => (
                        <Bar key={index} color={color} value={value} visible={onScreen} bgColor={bgColor}/>
                    ))}
                </BarsWrapper>

                {(hover && (showHover || hoverAlwaysOn)) && (
                    <HoverWrapper css={css`margin-top: ${data.length > 1 ? 15 : 0}px;`}>
                        <Hover text={hover} up={hoverUp && !hoverDown}/>
                    </HoverWrapper>
                )}
            </BarChartContainer>
        );
    }

    onMouseEnter () {
        this.setState({
            showHover: true
        });
    }

    onMouseLeave () {
        this.setState({
            showHover: false
        });
    }
}

BarChart.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    bgColor: PropTypes.string,
    image: PropTypes.string,
    onScreen: PropTypes.bool,
    hoverUp: PropTypes.bool,
    hoverDown: PropTypes.bool,
    hover: PropTypes.string,
    hoverAlwaysOn: PropTypes.bool
};

export default withOnScreen(BarChart);
