/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Link } from "react-scroll";
import { colors, fonts, grid } from "config";
import activeImage from "./active.png";
import hoverImage from "./hover.png";

const StyledLink = styled.li`
    cursor: pointer;
    display: block;
    position: relative;
    border-bottom: 1px solid ${colors.grey};
    
    & > a {
        display: block;
        padding: 10px;
        
        ${grid.breakpoints.desktop} {
            padding: 0 10px;
            height: 50px;
        }
    }
    
    &:hover > a {
        background: url(${hoverImage}) no-repeat center 10px;
        
        ${grid.breakpoints.desktop} {
            background-position: center top;
        }
    }
        
    & > a.active {
        background: url(${activeImage}) no-repeat center 10px;
        
        ${grid.breakpoints.desktop} {
            background-position: center top;
        }
    }
    
    ${grid.breakpoints.desktop} {
        height: 50px;
        display: inline-block;
        border-right: ${props => props.last ? 0 : 1}px solid ${colors.grey};
        border-bottom: 0;
        width: 16.666%;
    }
`;

const RoundNumber = styled.span`
    display: block;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-family: ${fonts.secondary};
`;

const SpanBlock = styled.span`
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    letter-spacing: -0.29px;
`;

const NavLink = ({ round, label, offset = -20, onClick, end = false }) => (
    <StyledLink last={end}>
        <Link activeClass="active" to={`round-${round}`} spy={true} smooth={false} offset={offset}
              onClick={onClick}>
            <RoundNumber>{round}</RoundNumber>
            <SpanBlock>{label}</SpanBlock>
        </Link>
    </StyledLink>
);

NavLink.propTypes = {
    round: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    offset: PropTypes.number,
    end: PropTypes.bool
};

export default NavLink;
