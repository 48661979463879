import styled from "@emotion/styled";
import { colors, grid } from "config";

const Description = styled.p`
    margin: 0 0 50px;
    padding: 0 0 50px;
    border-bottom: 2px solid ${colors.grey};
    font-size: 18px;
    text-align: center;
    line-height: 31px;
    letter-spacing: -0.37px;
    
    ${grid.breakpoints.desktop} {
        padding: 0 40px 50px;
        font-size: 20px;
        line-height: 38px;
        letter-spacing: -0.42px;
    }
`;

export default Description;
