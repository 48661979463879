/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { colors } from "../../../config";

const FooterLinks = styled.ul`
    list-style: none;
    padding: 0;
    margin: 20px 0;
`;

const FooterLink = styled.li`
    display: inline-block;
    padding: 0 20px 0 0;
    font-size: 14px;
    letter-spacing: -0.29px;
    line-height: 27px;
    
    a {
        color: ${colors.green};
        text-decoration: none;
    }
`;

const FooterNav = () => (
    <FooterLinks>
        <FooterLink>
            <a href="https://www.totaljobs.com/about/about-us">
                About&nbsp;Us
            </a>
        </FooterLink>
        <FooterLink>
            <a href="https://www.totaljobs.com/about/contact-us">
                Contact&nbsp;Us
            </a>
        </FooterLink>
        <FooterLink>
            <a href="https://www.totaljobs.com/about/privacy-policy">
                Privacy&nbsp;Policy
            </a>
        </FooterLink>
        <FooterLink>
            <a href="https://www.totaljobs.com/about/terms-and-conditions">
                Terms&nbsp;and&nbsp;Conditions
            </a>
        </FooterLink>
        <FooterLink>
            <a href="https://www.totaljobs.com/about/cookies">
                Cookies
            </a>
        </FooterLink>
    </FooterLinks>
);

export default FooterNav;
