/** @jsx jsx */

import { jsx } from "@emotion/core";
import crySVG from "assets/illustrations/cry2.svg";
import heartSVG from "assets/illustrations/heart/full.svg";
import violinSVG from "assets/illustrations/violin.svg";
import PercentageWithKey from "charts/PercentageWithKey/PercentageWithKey";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import ColumnHalf from "components/ColumnHalf";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import ColumnThird from "components/ColumnThird";
import Container from "components/Container";
import GoodCoContainer from "components/GoodCo/GoodCoContainer";
import GoodCoSideIntro from "components/GoodCo/GoodCoSideIntro";
import GraphTitle from "components/GraphTitle";
import RoundHeader from "components/RoundHeader/RoundHeader";
import Row from "components/Row";
import PercentageCircles from "../../charts/PercentageCircles/PercentageCircles";

const Round6 = () => (
    <div id="round-6">
        <Container>
            <RoundHeader round={6} label="Puzzle solved"
                         yourHealth={10} enemyHealth={0}
                         title="How to win when a relationship stops working">
                There are some unexpected silver linings to having a serious rift with a colleague. But none of them
                outweigh the benefits of resolving your conflict. And our survey reveals there’s a clear way to do that.
            </RoundHeader>

            <Appear>
                <Row>
                    <ColumnRight>
                        <BodyText>
                            Whether it’s finding greater motivation to do good work, sharing a deeper affinity with the
                            colleagues who we get along with (realising who our true allies are) or investing more in
                            life outside of work, there are a number of surprising (and positive) ways we respond to
                            feeling like we have a work enemy.
                        </BodyText>

                        <BodyText>
                            Some UK workers say that having a bad relationship with a colleague made them more committed
                            to their organisation. And a few workers even say they’d be sad if this person left!
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>

            <Appear>
                <Row>
                    <ColumnThird first>
                        <PercentageWithKey value={8} image={heartSVG}>
                            say having a work enemy made them more committed to the organisation
                        </PercentageWithKey>
                    </ColumnThird>
                    <ColumnThird middle>
                        <PercentageWithKey value={14} image={violinSVG}>
                            picked up a hobby or activity to take their mind off this colleague
                        </PercentageWithKey>
                    </ColumnThird>
                    <ColumnThird last>
                        <PercentageWithKey value={3} image={crySVG}>
                            say they would be sadder if this colleague left
                        </PercentageWithKey>
                    </ColumnThird>
                </Row>
            </Appear>

            <Appear>
                <Row marginTop={50}>
                    <ColumnRight>
                        <BodyText>
                            But remember: this is just a fraction compared to the 70% of unhappy people who feel forced
                            to go looking for a job elsewhere. So what’s the answer? The secret to defeating a work enemy
                            is simple: stop seeing them as one.
                        </BodyText>

                        <Row>
                            <ColumnHalf>
                                <PercentageCircles value={14}>
                                    of workers said things got better if they talked to someone else
                                </PercentageCircles>
                            </ColumnHalf>
                            <ColumnHalf>
                                <PercentageCircles value={23}>
                                    of workers said things got better if they talked directly to their ‘enemy’
                                </PercentageCircles>
                            </ColumnHalf>
                        </Row>
                    </ColumnRight>
                </Row>
            </Appear>
        </Container>

        <GoodCoContainer>
            <Appear>
                <Row>
                    <ColumnLeft>
                        <GoodCoSideIntro/>
                    </ColumnLeft>
                    <ColumnRight>
                        <GraphTitle>
                            Why communication is the secret to resolving a crisis between colleagues.
                        </GraphTitle>

                        <BodyText marginTop={50}>
                            Work enemies will try to avoid communicating when the relationship is fraught. But lack of
                            communication – or clashing styles – are the most obvious causes of misunderstanding and bad
                            feelings in the workplace.
                        </BodyText>

                        <BodyText>
                            So take note of what’s happening between you and your work enemy. Put your ego to one side,
                            try
                            to see yourself in the other person’s shoes, ask yourself, “How do they feel?” or “What
                            motivated them to behave the way they did?” Taking the other’s perspective is a powerful way
                            to
                            help rebuild a relationship gone sour.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>
        </GoodCoContainer>
    </div>
);

export default Round6;
