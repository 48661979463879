/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Promise from "bluebird";
import { colors, grid } from "config";
import { withOnScreen } from "hocs/onScreen";
import times from "lodash/times";
import PropTypes from "prop-types";
import { Component } from "react";
import posed from "react-pose";

const delay = 200;

const BarWrapper = styled.div`
    overflow: hidden; 
    margin: 10px 0;
`;

const Wrapper = styled.span`
    display: block;
    overflow: hidden;
    height: 16px;
    width: 14px;
    float: left;
    margin: 0 12px 0 0;
    
    ${grid.breakpoints.desktop} {
        margin: 0 10px 0 0;
    }
`;

const Fill = posed.rect({
    hidden: {
        width: 0
    },
    visible: {
        width: ({ width }) => width,
        transition: { duration: delay }
    }
});


class Bar extends Component {
    constructor () {
        super();

        this.state = {
            current: -1
        };
    }

    render () {
        const { total } = this.props;

        return (
            <BarWrapper>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                    <clipPath id={`circle-clip-path`}>
                        <path d="M12 4h2v6h-2v2h-2v2H4v-2H2v-2H0V4h2V2h2V0h6v2h2v2z"/>
                    </clipPath>
                </svg>
                {times(total).map((index) => (
                    <Wrapper key={index}>
                        {this.renderCircle(index)}
                    </Wrapper>
                ))}
            </BarWrapper>
        );
    }

    renderCircle (index) {
        const { current } = this.state;
        const visible = index < current;

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                <rect fill={colors.grey} x="0" y="0" width="14" height="14"
                      clipPath={`url(#circle-clip-path)`}/>
                <Fill fill={colors.blue} x="0" y="0" width="14" height="14"
                      clipPath={`url(#circle-clip-path)`}
                      pose={visible > 0 ? "visible" : "hidden"}/>
            </svg>
        );
    }

    shouldComponentUpdate ({ onScreen }) {
        if (onScreen !== this.props.onScreen) {
            this.animate();
        }

        return true;
    }

    animate () {
        const { current } = this.state;
        const { highlight } = this.props;

        if (current < highlight) {
            this.setState({
                current: current + 1
            });

            return Promise.delay(delay)
                .then(() => this.animate());
        }

        return Promise.resolve();
    }

}

Bar.propTypes = {
    total: PropTypes.number.isRequired,
    highlight: PropTypes.number.isRequired,
    onScreen: PropTypes.bool
};

export default withOnScreen(Bar);
