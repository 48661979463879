import styled from "@emotion/styled";
import { grid } from "../config";

const ColumnLeft = styled.div`
    padding: 0 ${grid.gutter}px;
        
    ${grid.breakpoints.desktop} {
        width: ${grid.left};
        float: left;
    }
`;

export default ColumnLeft;
