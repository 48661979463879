/** @jsx jsx */

import { jsx } from "@emotion/core";
import threeImage from "assets/illustrations/enemy/all.svg";
import oneImage from "assets/illustrations/enemy/one.svg";
import twoImage from "assets/illustrations/enemy/two.svg";
import PercentageCircles from "charts/PercentageCircles/PercentageCircles";
import StackedBar from "charts/StackedBar/StackedBar";
import Appear from "components/Appear";
import BodyText from "components/BodyText";
import Boxes from "components/Boxes/Boxes";
import ColumnLeft from "components/ColumnLeft";
import ColumnRight from "components/ColumnRight";
import Container from "components/Container";
import GraphTitle from "components/GraphTitle";
import Link from "components/Link";
import RoundHeader from "components/RoundHeader/RoundHeader";
import Row from "components/Row";
import { colors } from "../../config";

const Round1 = () => (
    <div>
        <Container>
            <RoundHeader round={1} label="Breakdown" yourHealth={10} enemyHealth={10} dots={false}
                         title="Most of us have a work enemy">
                Good working relationships can take months to build... but they can go wrong in a moment. Sadly, it
                seems most of us will experience this.
            </RoundHeader>

            <Appear>
                <Row>
                    <ColumnLeft>
                        <PercentageCircles value={62}>
                            of the 7,076 workers surveyed by Totaljobs admitted to having a work enemy
                        </PercentageCircles>
                    </ColumnLeft>

                    <ColumnRight>
                        <BodyText>
                            According to a Totaljobs survey of over 7,000 UK workers, 6 in 10 admitted they have at
                            least one ‘work enemy’. For some, it’s an annoying colleague who is always getting on your
                            nerves. But for others, it can be more serious, like an office bully causing much deeper
                            damage.
                        </BodyText>

                        <BodyText>
                            In <Link target="_blank" href="https://www.totaljobs.com/insidejob/work-spouse/">The World
                            of Work Spouses</Link>, Totaljobs discovered that only 2 in 10 UK workers have a close
                            workplace friendship. That means we’re three times more likely to develop a rift with a
                            colleague than a bond.
                        </BodyText>
                    </ColumnRight>
                </Row>
            </Appear>

            <Appear>
                <GraphTitle>
                    How many work enemies do we usually have?
                </GraphTitle>

                <StackedBar data={[{
                    percentage: 48,
                    color: colors.blue,
                    label: "Just one enemy",
                    image: oneImage
                }, {
                    percentage: 43,
                    color: colors.purple,
                    label: "More than one enemy",
                    image: twoImage
                }, {
                    percentage: 8,
                    color: colors.red,
                    label: "Most of our colleagues",
                    image: threeImage
                }]}/>
            </Appear>

        </Container>
        <Boxes/>
    </div>
);

export default Round1;
