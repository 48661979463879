/** @jsx jsx */

import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import BarChart from "charts/BarChart/BarChart";
import Appear from "components/Appear";
import GraphTitle from "components/GraphTitle";
import { colors } from "config";
import crySVG from "assets/illustrations/cry2.svg";
import isolateSVG from "assets/illustrations/isolate.svg";
import angrySVG from "assets/illustrations/angry.svg";
import unhealthySVG from "assets/illustrations/unhealthy.svg";
import hobbySVG from "assets/illustrations/healthy.svg";

const Wrapper = styled.div`
    margin: 0 0 50px;
`;

const BarChartWrapper = styled.div`
    margin: 20px 0;
`;

const KeyWrapper = styled.div`
    overflow: hidden;
    margin: 30px 0 0;
`;

const Key = styled.div`
    float: left;
    overflow: hidden;
    margin: 0 30px 0 0;
`;

const KeyColor = styled.span`
    display: block;
    background-color: ${props => props.color};
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 10px;
`;

const KeyLabel = styled.span`
    line-height: 18px;
    font-size: 14px;
`;

export default () => (
    <Wrapper>
        <Appear>
            <GraphTitle>Having a work enemy makes us...</GraphTitle>
        </Appear>

        <Appear>
            <KeyWrapper>
                <Key>
                    <KeyColor color={colors.lime}/>
                    <KeyLabel>Women</KeyLabel>
                </Key>
                <Key>
                    <KeyColor color={colors.purple}/>
                    <KeyLabel>Men</KeyLabel>
                </Key>
            </KeyWrapper>
        </Appear>

        <Appear>
            <BarChartWrapper>
                <BarChart title="Cry or get emotional" image={crySVG}
                          hover="Most common female reaction" hoverAlwaysOn hoverUp
                          data={[{
                              value: 62,
                              color: colors.lime
                          }, {
                              value: 29,
                              color: colors.purple
                          }]}/>
            </BarChartWrapper>
        </Appear>

        <Appear>
            <BarChartWrapper>
                <BarChart title="Isolate yourself from colleagues" image={isolateSVG}
                          hover="Most common male reaction" hoverAlwaysOn hoverDown
                          data={[{
                              value: 39,
                              color: colors.lime
                          }, {
                              value: 36,
                              color: colors.purple
                          }]}/>
            </BarChartWrapper>
        </Appear>

        <Appear>
            <BarChartWrapper>
                <BarChart title="Get angry" image={angrySVG}
                          data={[{
                              value: 29,
                              color: colors.lime
                          }, {
                              value: 32,
                              color: colors.purple
                          }]}/>
            </BarChartWrapper>
        </Appear>

        <Appear>
            <BarChartWrapper>
                <BarChart title="Pick up or increase unhealthy habits" image={unhealthySVG}
                          data={[{
                              value: 22,
                              color: colors.lime
                          }, {
                              value: 15,
                              color: colors.purple
                          }]}/>
            </BarChartWrapper>
        </Appear>

        <Appear>
            <BarChartWrapper>
                <BarChart title="Pick up a hobby or activity" image={hobbySVG}
                          data={[{
                              value: 12,
                              color: colors.lime
                          }, {
                              value: 32,
                              color: colors.purple
                          }]}/>
            </BarChartWrapper>
        </Appear>
    </Wrapper>
);
