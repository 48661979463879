import styled from "@emotion/styled";
import logo from "assets/logos/Good&Co.svg";

const Logo = styled.span`
  background: url(${logo}) no-repeat center center;
  width: 120px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
`;

export default Logo;
