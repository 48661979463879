import styled from "@emotion/styled";
import { colors, grid } from "config";

const HighlightSection = styled.div`
    background: ${colors.purple};
    padding: 50px 0;
    margin: 50px 0 0;
    
    ${grid.breakpoints.desktop} {
        padding: 100px 0;
    }
`;

export default HighlightSection;
