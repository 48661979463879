/** @jsx jsx */

import styled from "@emotion/styled";
import {fonts} from "config";

const Link = styled.a`
    color: ${fonts.colors.body};
    text-decoration: none;
    border-bottom: 2px solid ${fonts.colors.link};
    padding-bottom: 3px;
    transition: padding-bottom 300ms ease;
    
    &:hover {
        padding-bottom: 0;
    }
 `;

export default Link;
