import styled from "@emotion/styled";
import { grid } from "config";
import PropTypes from "prop-types";

const BodyText = styled.p`
    margin: ${props => props.marginTop ? props.marginTop : 0}px 0 50px;
    font-size: 18px;
    letter-spacing: -0.37px;
    line-height: 31px;
    text-align: ${props => props.center ? "center" : "left"};
    
    ${grid.breakpoints.desktop} {
        line-height: 38px;
    }
`;

BodyText.propTypes = {
    marginTop: PropTypes.number,
    center: PropTypes.bool
};

export default BodyText;
